import ThemeLogo from '@/components/theme-logo/ThemeLogo'
import { cn } from '@/utils/shadcn'
import { useTranslation } from 'react-i18next'

interface Tag {
  name: string
  slug: string
  icon?: { url: string }
}

interface TagFilterItemProps {
  selected: boolean
  onSelect: (slug: string) => void
  tag: Tag
  color: string
  textColor?: string
  showIcon?: boolean
}

export const colorVariants = [
  '#BBEAD0',
  '#CAE4F0',
  '#CCAAD1',
  '#E3A09C',
  '#ECC6DD',
  '#F0DCAC',
  '#F1BB89',
  '#949CC6',
]

const TagFilterItem = ({
  selected,
  onSelect,
  tag,
  color,
  textColor = '#08103A',
  showIcon,
}: TagFilterItemProps) => {
  return (
    <div
      className="flex items-center gap-2 h-9 w-fit cursor-pointer select-none rounded-[20px] border px-4 text-xs/[34px] font-medium duration-200 hover:!border-black"
      style={{
        backgroundColor: selected ? color : 'transparent',
        borderColor: selected ? color : 'rgba(8, 16, 58, 0.1)',
        color: textColor,
      }}
      onClick={() => onSelect(tag.slug)}
    >
      {tag.name}
      {showIcon && <ThemeLogo color={textColor} url={tag.icon?.url || ''} className="w-4 h-4" />}
    </div>
  )
}

const ThemeTagsFilter = ({
  tags,
  filteredTags,
  setFilteredTags,
  className,
  showIcon,
}: {
  tags: Tag[]
  filteredTags: string[]
  setFilteredTags: (tags: string[]) => void
  className?: string
  showIcon?: boolean
}) => {
  const { t } = useTranslation()
  const isSelectAll = filteredTags.length === tags.length

  const updateFilters = (tag: string) => {
    if (filteredTags.includes(tag)) {
      setFilteredTags(filteredTags.filter((item) => item !== tag))
    } else {
      setFilteredTags([...filteredTags, tag])
    }
  }

  return (
    <div className={cn('relative mt-4 w-full rounded-xl border p-4 lg:p-8', className)}>
      <span className="absolute -top-3 left-1/2 inline-block -translate-x-1/2 bg-white p-1 text-[10px] font-medium text-black/50">
        {t('label.select_tags')}
      </span>
      <div className="flex flex-wrap gap-2 lg:gap-3">
        <TagFilterItem
          tag={{ name: t('label.all'), slug: 'select-all' }}
          selected={isSelectAll}
          onSelect={() => {
            isSelectAll ? setFilteredTags([]) : setFilteredTags(tags.map((e) => e.slug))
          }}
          color="#08103A"
          textColor={isSelectAll ? 'white' : '#08103A'}
        />
        {tags.map((item) => (
          <TagFilterItem
            tag={item}
            selected={filteredTags.includes(item.slug)}
            onSelect={updateFilters}
            color={colorVariants[tags.indexOf(item) % colorVariants.length]}
            key={item.slug}
            showIcon={showIcon}
          />
        ))}
      </div>
    </div>
  )
}

export default ThemeTagsFilter
