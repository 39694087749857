import { ValueEditor, ValueEditorProps } from 'react-querybuilder'
import ThemesSelect from './ThemesSelect'
import CompaniesSelect from './CompaniesSelect'

export const CustomValueEditor = (props: ValueEditorProps) => {
  if (props.fieldData.datatype === 'themes-select') {
    return (
      <ThemesSelect
        value={props.value.split(',').filter((e) => !!e)}
        // @ts-ignore
        onChange={(v) => props.handleOnChange(v.filter((e) => !!e).join(','))}
      />
    )
  }
  if (props.fieldData.datatype === 'companies-select') {
    return (
      <CompaniesSelect
        value={props.value.split(',').filter((e) => !!e)}
        // @ts-ignore
        onChange={(v) => props.handleOnChange(v.filter((e) => !!e).join(','))}
      />
    )
  }
  return <ValueEditor {...props} />
}
