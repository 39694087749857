import * as React from 'react'
import { ChevronDown } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandItem } from '@/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Checkbox } from './ui/checkbox'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import { Sheet, SheetContent, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from './ui/sheet'
import { cn } from '@/utils/shadcn'
import { Input } from './ui/input'

export interface Props {
  value?: string
  onChange: (value?: string) => void
  options: { label: string | React.ReactNode; value: string }[]
  customButton?: React.ReactNode
  selectPlaceholder?: string
  searchPlaceholder?: string
  contentWidth?: number
  desktopCols?: number
  mobileHeader?: string
  showSearch?: boolean
  popupClassName?: string
  buttonClassName?: string
  contentClassName?: string
  hideCancel?: boolean
  sideOffset?: number
  hideOnClick?: boolean
}

export function FilterDropdown({
  value,
  options,
  onChange,
  customButton,
  selectPlaceholder,
  searchPlaceholder,
  contentWidth = 320,
  desktopCols = 1,
  mobileHeader,
  showSearch,
  popupClassName,
  buttonClassName,
  contentClassName,
  hideCancel,
  sideOffset,
  hideOnClick,
}: Props) {
  const { isMobile } = useWindowDimensions()
  const [search, setSearch] = React.useState('')
  const deferredSearch = React.useDeferredValue(search)

  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const [innerValue, setInnerValue] = React.useState(value)

  const handleOpenChange = (open: boolean) => {
    if (!open && innerValue !== value) {
      setInnerValue(value)
    }
    setOpen(open)
  }

  const handleClose = () => {
    setInnerValue(value)
    setOpen(false)
  }

  const handleSave = () => {
    onChange(innerValue)
    setOpen(false)
  }

  const handleSelect = (value?: string) => {
    setInnerValue(value === innerValue ? undefined : value)
    if (hideOnClick) {
      onChange(value)
      setOpen(false)
    }
  }

  const trigger = (
    <div
      className={cn(
        'flex w-fit cursor-pointer select-none items-center justify-between',
        buttonClassName,
      )}
    >
      {customButton ||
        (value
          ? options.find((option) => option.value === value)?.label
          : selectPlaceholder || t('select_placeholder'))}
      <ChevronDown
        className={cn('ml-2 h-5 w-5 shrink-0 opacity-50 transition duration-150', {
          'rotate-180 text-brand-torea !opacity-100': open,
        })}
      />
    </div>
  )

  const content = (
    <Command className={contentClassName}>
      {showSearch && (
        <Input
          placeholder={searchPlaceholder || t('search_placeholder')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          inputClassName="-mt-5 mb-2"
        />
      )}
      <CommandEmpty>{t('no_item_found')}</CommandEmpty>
      <CommandGroup className="max-h-[35vh] flex-1 overflow-auto">
        <div className={cn({ [`grid grid-cols-${desktopCols} gap-4`]: !isMobile })}>
          {options
            .filter((e) => e.label?.toString().toLowerCase().includes(deferredSearch.toLowerCase()))
            .map((option, i) => (
              <CommandItem
                className="cursor-pointer "
                key={`${option.value}_${i}`}
                onSelect={() => handleSelect(option.value)}
              >
                {!hideOnClick && (
                  <Checkbox checked={option.value === innerValue} className="mr-2" />
                )}
                {option.label}
              </CommandItem>
            ))}
        </div>
      </CommandGroup>
    </Command>
  )

  if (!isMobile) {
    return (
      <Popover open={open} onOpenChange={handleOpenChange}>
        <PopoverTrigger asChild>{trigger}</PopoverTrigger>
        <PopoverContent
          className={cn('!rounded-18px p-6 shadow-dialog', popupClassName)}
          style={{ width: contentWidth }}
          align="start"
          sideOffset={sideOffset}
        >
          {content}
          {!hideOnClick && (
            <div
              className={
                desktopCols > 1
                  ? 'flex w-full justify-end pt-3 gap-4'
                  : cn('grid gap-4 pt-3', hideCancel ? 'grid-cols-1' : 'grid-cols-2')
              }
            >
              {!hideCancel && (
                <Button variant="ghost" onClick={handleClose}>
                  {t('button.cancel')}
                </Button>
              )}
              <Button onClick={handleSave}>{t('button.save')}</Button>
            </div>
          )}
        </PopoverContent>
      </Popover>
    )
  }

  return (
    <Sheet open={open} onOpenChange={handleOpenChange}>
      <SheetTrigger asChild>{trigger}</SheetTrigger>
      <SheetContent side="bottom" className="rounded-t-4xl p-0">
        <div className="flex flex-col">
          <SheetHeader className="mb-4">
            <SheetTitle>{mobileHeader || t('filter_placeholder')}</SheetTitle>
          </SheetHeader>
          <div className={cn('px-4', popupClassName)}>{content}</div>
          {!hideOnClick && (
            <SheetFooter>
              <div className={cn('grid gap-4', hideCancel ? 'grid-cols-1' : 'grid-cols-2')}>
                {!hideCancel && (
                  <Button onClick={handleClose} variant="ghost">
                    {t('button.cancel')}
                  </Button>
                )}
                <Button onClick={handleSave}>{t('button.save')}</Button>
              </div>
            </SheetFooter>
          )}
        </div>
      </SheetContent>
    </Sheet>
  )
}
