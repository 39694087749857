import { graphql } from '@/gql'

export const emailLoginInfo = graphql(`
  query emailLoginInfo($emailCode: String!) {
    emailLoginInfo(input: { emailCode: $emailCode }) {
      user {
        firstName
        lastName
        email
        phoneNumber
      }
      organization {
        ... on CompanyType {
          name
        }
        ... on VendorType {
          name
        }
      }
    }
  }
`)

export const currentUserInfoQuery = graphql(`
  query me {
    me {
      id
      email
      firstName
      lastName
      phoneNumber
      familyStatus
      gender
      dateOfBirth
      language
      isSuperuser
      isStaff
      isCustomerSupport
      lastLogin
      companyMember {
        company {
          logo(resize: { fill: { width: 80, height: 80 } }) {
            url
          }
          name
          slug
          budgetPerEmployee
          maxBudgetPerEmployee
          companyBudget
          accountType
        }
        isOwner
        isAdmin
        isEmployee
        budget
        totalBudget
        workEnergy
        privateEnergy
      }
      vendorMember {
        vendor {
          name
          slug
          smallImage(resize: { fill: { width: 80, height: 80 } }) {
            url
          }
        }
      }
      userThemes {
        id
        isFavorite
        theme {
          name
          slug
          icon(resize: { fill: { width: 32, height: 32 } }) {
            url
          }
        }
      }
    }
  }
`)

export const termsQuery = graphql(`
  query TermsQuery {
    terms {
      accepted
      content
      createdAt
      version
    }
  }
`)
