import { Button } from '@/components/ui/button'
import useModalStore from '@/stores/useModalStore'
import { Loader2, Pencil } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { updateMessage } from '../../graphql/mutation'
import { useMutationGraphQL } from '@/use-graphql'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Textarea } from "@/components/ui/textarea"

const EditMessageButton = ({ id, content }: { id: string; content: string }) => {
  const queryClient = useQueryClient()
  const { addModal } = useModalStore()
  const { t } = useTranslation()
  

  const showConfirmModal = () => {
    addModal({
      name: 'send-email-notification',
      Component: ({ close }) => {
          const [loading, setLoading] = useState(false)
          const [text, setText] = useState(content)
          const updateArchivedStateOfMessage = useMutationGraphQL(
            updateMessage,
            { id, input: { content: text } },
            {
              onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['messages'] })
              },
              onError: () => {
                toast.error('Failed to edit message')
              },
            },
          )

          return (
          <div className="p-10">
            <Textarea
              rows={8}
              style={{ resize: 'none' }}
              value={text}
              onChange={(e) => setText(e.target.value)}
            ></Textarea>
            <div className="mt-10 flex justify-end gap-4">
              <Button
                onClick={async () => {
                  try{
                    setLoading(true)
                    await updateArchivedStateOfMessage.mutateAsync()
                    setTimeout(() => close?.(), 300)
                  } finally {
                    setLoading(false)
                  }
                }}
                disabled={loading}
                className="relative"
              >
                {t('button.submit')}
                {loading && <Loader2 className="absolute left-2 top-3 animate-spin !text-white" size={20} />}
              </Button>
              <Button variant="outline" onClick={close}>
                {t('button.cancel')}
              </Button>
            </div>
          </div>
        )
      },
      isCardStyle: true,
      maxWidth: 700,
    })
  }

  return (
    <button onClick={showConfirmModal} className="hover:text-brand-valencia">
     <Pencil size={20} />
    </button>
  )
}

export default EditMessageButton
