import { useMutationGraphQL, useQueryGraphQL } from '@/use-graphql'
import { useTranslation } from 'react-i18next'
import { bookingQuery } from '../../graphql/query'
import { BookingsBookingStatusEnum } from '@/gql/graphql'
import { Button } from '@/components/ui/button'
import { bookingDateApprovalMutation } from '../../graphql/mutation'

const AcceptChangeDate = ({ type }: { type?: string | null }) => {
  const { t } = useTranslation()
  const id = type?.split('__').pop()
  const { data, refetch } = useQueryGraphQL(
    ['booking'],
    bookingQuery,
    { id: id || '' },
    { enabled: !!id },
  )
  const approveMutate = useMutationGraphQL(
    bookingDateApprovalMutation,
    { id: +(id || 0) },
    { onSuccess: () => refetch() },
  )
  if (
    !data?.booking.status ||
    data?.booking.status !== BookingsBookingStatusEnum.NeedsEmployeeAction
  ) {
    return
  }

  return (
    <Button
      variant="secondary"
      size="sm"
      className="mt-2 block"
      onClick={() => approveMutate.mutate()}
    >
      {t('button.accept')}
    </Button>
  )
}

export default AcceptChangeDate
