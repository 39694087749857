import { getDisplayCharacters } from '@/utils/handle-string'
import { cn } from '@/utils/shadcn'

interface Props {
  className?: string
  src?: string
  hasNotification?: boolean
  name?: string
}

const Avatar = ({ className, src, name, hasNotification }: Props) => {
  return (
    <div
      className={cn(
        'relative flex h-6 w-6 shrink-0 items-center justify-center rounded-full md:h-10 md:w-10',
        { 'bg-brand-cream text-deep-cove': !src },
        className,
      )}
    >
      {src ? (
        <img src={src} alt="avatar" className="h-full w-full rounded-full object-cover" />
      ) : (
        <p>{getDisplayCharacters(name || '')}</p>
      )}
      {hasNotification && (
        <div className="absolute -right-1 top-0 h-2.5 w-2.5 rounded-full border-2 border-white  bg-brand-valencia">
          <span className="absolute left-0 inline-flex h-full w-full animate-ping rounded-full bg-brand-valencia opacity-75"></span>
        </div>
      )}
    </div>
  )
}

export default Avatar
