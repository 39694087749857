import {
  Tooltip as ToolTipLib,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { ReactNode } from 'react'

export function Tooltip({
  children,
  content,
  className,
  delayDuration = 300,
}: {
  children: ReactNode
  content: ReactNode
  className?: string
  delayDuration?: number
}) {
  return (
    <TooltipProvider delayDuration={delayDuration}>
      <ToolTipLib>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent className={className}>{content}</TooltipContent>
      </ToolTipLib>
    </TooltipProvider>
  )
}
