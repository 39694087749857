import { useRef } from 'react'
import { X } from 'lucide-react'
import { motion } from 'framer-motion'

import { ContactAvatar } from '../contacts/ContactItem'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import MessageList from './MessageList'
import useMessageStore, { ParticipantInfo } from '@/stores/useMessageStore'
import { Button } from '@/components/ui/button'
import { AnimatePresence } from 'framer-motion'
import MessageInput from './MessageInput'
import { ErrorMessage } from '@/components/ErrorMessage'
import { ErrorBoundary } from '@sentry/react'
import SearchAndFilter from './components/SearchAndFilter'
import useAuthStore from '@/stores/useAuthStore'
import { cn } from '@/utils/shadcn'

const DesktopContent = () => {
  const { activeChat } = useMessageStore()
  const { isStaff, user } = useAuthStore()
  const messageListRef = useRef<HTMLDivElement>(null)

  return (
    <ErrorBoundary fallback={<ErrorMessage />}>
      <div className="h-full w-full">
        <div className="flex flex-col border-b md:px-4 md:py-3.5 lg:flex-row lg:items-center lg:justify-between lg:gap-4 lg:px-8 lg:py-5">
          <ContactAvatar name={activeChat?.name || ''} image={activeChat?.image || ''} />
          {(isStaff() || user?.isCustomerSupport) && <SearchAndFilter />}
        </div>
        <div
          className={cn(
            'flex h-[calc(100%-73px)] flex-col justify-between overflow-hidden bg-grey-light lg:h-[calc(100%-105px)]',
            { 'h-[calc(100%-132px)]': isStaff() || user?.isCustomerSupport },
          )}
        >
          <MessageList ref={messageListRef} />
          <div className="mb-2 shrink-0 px-4">
            <MessageInput messageListRef={messageListRef} />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

const MobileContent = ({ contact }: { contact: ParticipantInfo }) => {
  const { activeChat, setActiveChat } = useMessageStore()
  const { isStaff, user } = useAuthStore()
  const isStaffOrCS = isStaff() || user?.isCustomerSupport
  const handleClose = () => setActiveChat(null)
  const messageListRef = useRef<HTMLDivElement>(null)

  return (
    <ErrorBoundary fallback={<ErrorMessage />}>
      <AnimatePresence>
        {!!activeChat && (
          <motion.div
            initial={{ bottom: '-100%', opacity: 0 }}
            animate={{ bottom: 0, opacity: 1 }}
            exit={{ bottom: '-100%', opacity: 0 }}
            className="fixed z-[999] flex h-screen  w-full flex-col justify-end"
            onClick={handleClose}
          >
            <div className="-z-1 absolute -top-[100%] left-0  h-[200vh] w-full bg-[rgba(0,0,0,0.5)]"></div>
            <div
              className="content-page absolute w-full rounded-t-4xl bg-white"
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className={cn('flex shrink-0 justify-between border-b px-4 py-4.5', {
                  'pb-1.5': isStaffOrCS,
                })}
              >
                <div>
                  <ContactAvatar name={contact?.name || ''} image={contact?.image || ''} />
                  {isStaffOrCS && <SearchAndFilter />}
                </div>
                <Button size="icon" className="mr-2" onClick={handleClose}>
                  <X className="text-white" />
                </Button>
              </div>
              <div
                className={cn('flex h-[calc(100%-89px)] flex-col justify-between bg-white', {
                  'h-[calc(100%-128px)]': isStaffOrCS,
                })}
              >
                <MessageList ref={messageListRef} />
                <MessageInput messageListRef={messageListRef} />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </ErrorBoundary>
  )
}

const MessagesBoard = () => {
  const { width } = useWindowDimensions()
  const { activeChat } = useMessageStore()

  if (!activeChat) {
    return null
  }
  if (width > 768) {
    return <DesktopContent />
  }

  return <MobileContent contact={activeChat} />
}

export default MessagesBoard
