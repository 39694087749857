import { useQueryClient } from '@tanstack/react-query'
import MessageItem from '../MessageItem'
import { useTranslation } from 'react-i18next'
import { MESSAGE_LIMIT } from '@/pages/messages/messages-board/MessageList'
import { useMutationGraphQL } from '@/use-graphql'
import { sendMessageMutation } from '@/pages/messages/graphql/mutation'
import { useState } from 'react'
import { produce } from 'immer'
import useAuthStore from '@/stores/useAuthStore'

const HelpMessageList = ({ chatId }: { chatId: string }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [message, setMessage] = useState('')
  const templateMessages = [t('help_message.template1'), t('help_message.template2')]
  const { user } = useAuthStore()

  const sendMessageMutate = useMutationGraphQL(sendMessageMutation, {
    input: { chatId: chatId || '', content: message },
  })

  function handleSendMessage(message: string) {
    const messagesQueryKey = [
      'messages',
      {
        chatId: chatId,
        pagination: {
          limit: MESSAGE_LIMIT,
        },
      },
    ]
    sendMessageMutate.mutate()
    queryClient.cancelQueries({ queryKey: messagesQueryKey })
    queryClient.setQueryData(messagesQueryKey, (oldData: any) => {
      return produce(oldData, (draftState: any) => {
        draftState?.pages?.[0]?.chat?.messages?.items?.unshift({
          id: new Date().toISOString(),
          content: message,
          createdAt: new Date().toISOString(),
          direction: 'MessageDirection.OUT',
        })
        draftState.pages[0].chat.messages.totalItemsCount = 1
      })
    })
  }

  const sendMessage = (message: string) => {
    setMessage(message)
    handleSendMessage(message)
  }

  return (
    <div className="flex h-full flex-col justify-between">
      <MessageItem
        isSender={false}
        key={-1}
        text={`Hi ${user?.firstName || ''}! ${t('label.customer_support_help_question')}`}
        date={new Date().toISOString()}
      />
      <div className="w-10/12 space-y-1 text-sm text-white">
        <p className="text-grey-black">{t('help_message.call_to_action')}</p>
        {templateMessages.map((message, i) => (
          <div
            className="cursor-pointer rounded-xl bg-secondary-arapawa p-2.5"
            onClick={() => sendMessage(message)}
            key={i}
          >
            {message}
          </div>
        ))}
      </div>
    </div>
  )
}

export default HelpMessageList
