import { useQueryGraphQL } from '@/use-graphql'
import { segmentsQuery } from '../graphql/query'
import SegmentItem from '../components/SegmentItem'
import { Link } from 'react-router-dom'
import { routes } from '@/routes/utils'
import Pagination from '@/components/Pagination'
import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Plus } from 'lucide-react'

const LIMIT = 24

const SegmentList = () => {
  const [page, setPage] = useState(1)
  const { data } = useQueryGraphQL(['segments'], segmentsQuery, {
    pagination: { limit: LIMIT, offset: (page - 1) * LIMIT },
  })
  const totalPages = Math.ceil((data?.segments?.totalItemsCount || 0) / LIMIT)

  return (
    <div className="pb-8">
      <Link to={routes.segmentDetail.getPath('create')} className="my-3 block">
        <Button variant={'outline'} size="sm">
          <Plus />
          Create Segment
        </Button>
      </Link>
      {data?.segments.items.map((segment: any) => (
        <Link to={routes.segmentDetail.getPath(segment.id)} key={segment.id} className="my-4 block">
          <SegmentItem segment={segment} />
        </Link>
      ))}
      {totalPages > 1 && (
        <div className="mt-10 flex w-full justify-end">
          <Pagination currentPage={page} totalPages={totalPages} onPageChange={setPage} />
        </div>
      )}
    </div>
  )
}

export default SegmentList
