import { useEffect } from 'react'
import ContactList, { CONTACT_LIMIT } from './contacts/ContactList'
import MessagesBoard from './messages-board/MessagesBoard'
import useMessageStore from '@/stores/useMessageStore'
import { ErrorBoundary } from '@sentry/react'
import { ErrorMessage } from '@/components/ErrorMessage'
import { useSearchParams } from 'react-router-dom'
import { useMutationGraphQL } from '@/use-graphql'
import { createChatMutation } from './graphql/mutation'
import { getParticipant } from '@/utils/message'
import { useQueryClient } from '@tanstack/react-query'

const MessagePage = () => {
  const [searchParams] = useSearchParams()
  const chat = searchParams.get('chat')
  const name = searchParams.get('name')
  const { setActiveChat } = useMessageStore()
  const queryClient = useQueryClient()
  const createChatMutate = useMutationGraphQL(
    createChatMutation,
    {
      input: { toThriveCs: true },
    },
    {
      onSuccess: (data: any) => {
        const participant = getParticipant(data.createChat)
        setActiveChat({
          id: data.createChat.id,
          name: participant.name || 'Thrive customer support',
          image: '',
        })
        queryClient.invalidateQueries({
          queryKey: ['chat-contacts', { pagination: { limit: CONTACT_LIMIT } }],
        })
      },
    },
  )

  useEffect(() => {
    return () => {
      setTimeout(() => {
        setActiveChat(null)
      }, 1000)
    }
  }, [])

  useEffect(() => {
    if (chat === 'customer-support') {
      createChatMutate.mutate()
    }
    else if (chat && chat.search(/\d+/) >= 0) {
      setActiveChat({
        id: chat || '',
        name: name || ''
      })
    }
  }, [chat])

  return (
    <ErrorBoundary fallback={<ErrorMessage />}>
      <div className="content-page mx-auto flex flex-col w-full md:flex-row">
        <ContactList />
        <div className="h-full flex-1 overflow-hidden md:col-span-2">
          <MessagesBoard />
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default MessagePage
