import { formatDate } from '@/utils/datetime'
import _ from 'lodash'
import {
  defaultRuleProcessorMongoDB,
  formatQuery,
  RuleProcessor,
  RuleType,
} from 'react-querybuilder'

// const allOperators = [
//   '!=',
//   '=',
//   '>=',
//   '>',
//   '<=',
//   '<',
//   'doesNotBeginWith',
//   'beginsWith',
//   'doesNotEndWith',
//   'endsWith',
//   'doesNotContain',
//   'contains',
//   'notBetween',
//   'between',
//   'notIn',
//   'in',
//   'null',
//   'notNull',
// ]

const getBirthDate = (age: number) => {
  if (Number.isNaN(age)) return ''
  const currentDate = new Date()
  currentDate.setFullYear(currentDate.getFullYear() - age)
  return formatDate(currentDate, 'yyyy-MM-dd')
}

const ageToDate = (age: string): string => {
  if (!age) return ''
  if (age.includes(',')) {
    const [min, max] = age.split(',').map((a) => parseInt(a, 10))
    return `${getBirthDate(max)},${getBirthDate(min)}`
  }
  return getBirthDate(parseInt(age, 10))
}

const convertOperatorForAgeRule = (operator: string) => {
  if (operator === '>=') return '<='
  if (operator === '>') return '<'
  if (operator === '<=') return '>='
  if (operator === '<') return '>'
  return operator
}

const convertStringToArray = (value: string) => value.replace(/\s/g, '').split(',')

const convertSlugRule = (rule: RuleType) => {
  if (rule.operator === '=') {
    return { slug: { exact: rule.value } }
  }
  if (rule.operator === '!=') {
    return { NOT: { slug: { exact: rule.value } } }
  }
  if (rule.operator === 'in') {
    return { slug: { inList: convertStringToArray(rule.value) } }
  }
  if (rule.operator === 'notIn') {
    return { NOT: { slug: { inList: convertStringToArray(rule.value) } } }
  }
  return defaultRuleProcessorMongoDB(rule)
}

export const customRuleProcessor: RuleProcessor = (rule, options) => {
  if (rule.field === 'hasPhoneNumber') {
    return JSON.stringify({ phoneNumber: { isNull: !rule.value } })
  }
  if (rule.field === 'registered') {
    return JSON.stringify({ lastLogin: { isNull: !rule.value } })
  }
  if (rule.field === 'themes') {
    return JSON.stringify({
      userThemes: { isFavorite: { exact: true }, theme: { ...convertSlugRule(rule) } },
    })
  }
  if (rule.field === 'themeScore') {
    try {
      const [score, theme] = rule.value.split(' ON ')
      return JSON.stringify({
        userThemes: {
          theme: { slug: { exact: theme } },
          ...JSON.parse(
            defaultRuleProcessorMongoDB(
              { ...rule, field: 'score', value: (Number(score) - 1) / 4 },
              options,
            ),
          ),
        },
      })
    } catch {
      return defaultRuleProcessorMongoDB(rule, options)
    }
  }
  if (rule.field === 'role') {
    return JSON.stringify({ companyMemberships: { [rule.value]: { exact: true } } })
  }
  if (rule.field === 'company') {
    return JSON.stringify({ companyMemberships: { company: { ...convertSlugRule(rule) } } })
  }
  if (rule.field === 'age') {
    return customRuleProcessor(
      {
        ...rule,
        field: 'dateOfBirth',
        value: ageToDate(rule.value),
        operator: convertOperatorForAgeRule(rule.operator),
      },
      options,
    )
  }
  if (rule.field === 'budget') {
    try {
      return JSON.stringify({
        companyMemberships: JSON.parse(defaultRuleProcessorMongoDB(rule, options)),
      })
    } catch {
      return defaultRuleProcessorMongoDB(rule, options)
    }
  }
  if (rule.field === 'completedSelfscan') {
    return JSON.stringify({ completedSelfscan: rule.value })
  }

  if (rule.operator === '=') {
    return JSON.stringify({ [rule.field]: { exact: rule.value } })
  }
  if (rule.operator === '!=') {
    return JSON.stringify({ NOT: { [rule.field]: { exact: rule.value } } })
  }
  if (rule.operator === 'null') {
    return JSON.stringify({ [rule.field]: { isNull: true } })
  }
  if (rule.operator === 'notNull') {
    return JSON.stringify({ [rule.field]: { isNull: false } })
  }
  if (rule.operator === 'in') {
    return JSON.stringify({ [rule.field]: { inList: convertStringToArray(rule.value) } })
  }
  if (rule.operator === 'notIn') {
    return JSON.stringify({
      NOT: { [rule.field]: { inList: convertStringToArray(rule.value) } },
    })
  }

  return defaultRuleProcessorMongoDB(rule, options)
}

function sortItemsByValueType(query: any): any {
  if (Array.isArray(query)) {
    return query.sort((a, b) => {
      const aValue = Object.values(a)[0]
      const bValue = Object.values(b)[0]
      if (Array.isArray(aValue) && !Array.isArray(bValue)) {
        return 1
      } else if (!Array.isArray(aValue) && Array.isArray(bValue)) {
        return -1
      } else {
        return 0
      }
    })
  }

  const result = {}
  for (const key in query) {
    if (typeof query[key] === 'object') {
      result[key] = sortItemsByValueType(query[key])
    } else {
      result[key] = query[key]
    }
  }

  return result
}

export function flattenQuery(query: any): any {
  for (const key in query) {
    if (key === 'expr') {
      delete query[key]
    } else if ((key === 'OR' || key === 'AND') && Array.isArray(query[key])) {
      const result = {}
      let current = result

      for (let i = 0; i < query[key].length; i++) {
        current[key] = _.merge({}, flattenQuery(query[key][i]))

        if (i < query[key].length - 1) {
          current = current[key]
        }
      }

      query[key] = result
    } else if (typeof query[key] === 'object') {
      query[key] = flattenQuery(query[key])
    }
  }

  return query
}

export const convertQuery = (query) => {
  return sortItemsByValueType(
    JSON.parse(
      JSON.parse(
        JSON.stringify(
          formatQuery(query, { format: 'mongodb', ruleProcessor: customRuleProcessor }),
        )
          .replace(/\$\w+/g, (match) => match.slice(1))
          .replace(/"(or|and)\\":/g, (match) => match.toUpperCase()),
      ),
    ),
  )
}

export const predefinedVariables = [
  { id: 'first_name', display: '{{first_name}}' },
  { id: 'last_name', display: '{{last_name}}' },
  { id: 'full_name', display: '{{full_name}}' },
  { id: 'email', display: '{{email}}' },
  { id: 'company_name', display: '{{company_name}}' },
  { id: 'budget', display: '{{budget}}' },
]
