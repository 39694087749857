import CssFilterConverter from 'css-filter-converter'
import { useEffect, useState } from 'react'

interface Props {
  url: string
  color: string
  hoverColor?: string
  className?: string
}

const ThemeLogo = ({ url, color, hoverColor, className }: Props) => {
  const [displayedColor, setDisplayedColor] = useState(color)
  function convertColorToFilter() {
    if (displayedColor.startsWith('#')) {
      return CssFilterConverter.hexToFilter(displayedColor).color
    }
    if (displayedColor.startsWith('rgb')) {
      return CssFilterConverter.rgbToFilter(displayedColor).color
    }
    return CssFilterConverter.keywordToFilter(displayedColor).color
  }

  useEffect(() => {
    setDisplayedColor(color)
  }, [color])

  return (
    <div className={className}>
      <object
        type="image/svg+xml"
        width="100%"
        height="100%"
        data={url}
        style={{
          filter: convertColorToFilter() || '',
          transition: '0.2s',
          pointerEvents: 'none',
        }}
        onMouseEnter={() => hoverColor && setDisplayedColor(hoverColor)}
        onMouseLeave={() => hoverColor && setDisplayedColor(color)}
      ></object>
    </div>
  )
}

export default ThemeLogo
