import { useSearchParams } from 'react-router-dom'

const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const page = Number(searchParams.get('page')) || 0
  const limit = Number(searchParams.get('limit')) || 25

  const updateSearchParams = (pageIndex, pageSize) => {
    setSearchParams(
      {
        ...searchParams,
        page: pageIndex.toString(),
        limit: pageSize.toString(),
      },
      { replace: true },
    )
  }

  return { page, limit, updateSearchParams }
}

export default usePagination
