import { create } from 'zustand'

const useNavStore = create<{
  showManagement: boolean
  setShowManagement: (value: boolean) => void
}>((set) => ({
  showManagement: false,
  setShowManagement: (showManagement: boolean) => set({ showManagement }),
}))

export default useNavStore
