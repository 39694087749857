import { themesQuery } from '@/components/navbar/graphql/query'
import { MultiSelect } from '@/components/ui/multi-select'
import { useQueryGraphQL } from '@/use-graphql'
import { SetStateAction } from 'react'

const ThemesSelect = ({
  value,
  onChange,
}: {
  value: string[]
  onChange: (values: SetStateAction<string[]>) => void
}) => {
  const { data: themes } = useQueryGraphQL(
    ['themes'],
    themesQuery,
    {},
    { staleTime: 30 * 60 * 1000, cacheTime: 60 * 60 * 1000 },
  )

  return (
    <MultiSelect
      options={themes?.themes.map((theme) => ({ label: theme.name, value: theme.slug })) || []}
      selected={value}
      onChange={onChange}
    />
  )
}

export default ThemesSelect
