export const routes = {
  requestCode: {
    route: '/request-code',
    getPath: () => '/request-code',
  },
  login: {
    route: '/login/:emailCode',
    getPath: (emailCode) => `/login/${emailCode}`,
  },
  register: {
    route: '/:orgType/:orgSlug/invite/:emailCode',
    getPath: (orgType, orgSlug, emailCode) => `/${orgType}/${orgSlug}/invite/${emailCode}`,
  },
  logout: {
    route: '/logout',
    getPath: () => '/logout',
  },
  unauthorized: {
    route: '/unauthorized',
    getPath: () => '/unauthorized',
  },
  profile: {
    route: '/profile',
    getPath: () => '/profile',
  },
  messages: {
    route: '/messages',
    getPath: () => '/messages',
  },
  theme: {
    route: '/themes/:slug',
    getPath: (slug: string) => `/themes/${slug}`,
  },
  serviceDetail: {
    route: '/services/:slug',
    getPath: (slug: string) => `/services/${slug}`,
  },
  productDetail: {
    route: '/products/:slug',
    getPath: (slug: string) => `/products/${slug}`,
  },
  vendors: {
    route: '/vendors',
    getPath: () => '/vendors',
  },
  vendorDetail: {
    route: '/vendors/:slug',
    getPath: (slug: string) => `/vendors/${slug}`,
  },
  blogs: {
    route: '/blogs',
    getPath: () => '/blogs',
  },
  blogDetail: {
    route: '/blogs/:slug',
    getPath: (slug: string) => `/blogs/${slug}`,
  },
  guides: {
    route: '/guides',
    getPath: () => '/guides',
  },
  guideDetail: {
    route: '/guides/:slug',
    getPath: (slug: string) => `/guides/${slug}`,
  },
  booking: {
    route: '/booking',
    getPath: () => '/booking',
  },
  selfscan: {
    route: '/selfscan',
    getPath: () => '/selfscan',
  },
  checkIn: {
    route: '/checkin',
    getPath: () => '/checkin',
  },
  favorites: {
    route: '/favorites',
    getPath: () => '/favorites',
  },
  infoRequest: {
    route: '/info-request/:slug',
    getPath: (slug: string) => `/info-request/${slug}`,
  },
  tagPage: {
    route: '/tags/:slug',
    getPath: (slug: string) => `/tags/${slug}`,
  },
  searchPage: {
    route: '/search',
    getPath: () => '/search',
  },

  // Company admin
  companyDashboard: {
    route: '/company-dashboard',
    getPath: () => '/company-dashboard',
  },
  manageSeats: {
    route: '/manage-seats',
    getPath: () => '/manage-seats',
  },
  teamOffer: {
    route: '/team-offer',
    getPath: () => '/team-offer',
  },
  manageRequests: {
    route: '/manage-requests',
    getPath: () => '/manage-requests',
  },
  companyStatistics: {
    route: '/company-statistics',
    getPath: () => '/company-statistics',
  },

  // Vendor member
  vendorDashboard: {
    route: '/vendor-dashboard',
    getPath: () => '/vendor-dashboard',
  },
  sharedInfoDetail: {
    route: '/shared-info/:slug',
    getPath: (slug: string) => `/shared-info/${slug}`,
  },

  // Customer support
  customerDashboard: {
    route: '/customer-support',
    getPath: () => '/customer-support',
  },
  company: {
    route: '/company/:slug',
    getPath: (slug: string) => `/company/${slug}`,
  },
  userInfoDetail: {
    route: '/user-info/:id',
    getPath: (id: string) => `/user-info/${id}`,
  },
  bookings: {
    route: '/bookings',
    getPath: () => '/bookings',
  },
  campaignDashboard: {
    route: '/campaign-dashboard',
    getPath: () => '/campaign-dashboard',
  },
  segments: {
    route: '/campaign-dashboard/segments',
    getPath: '/campaign-dashboard/segments',
  },
  segmentDetail: {
    route: '/campaign-dashboard/segments/:id',
    getPath: (id) => `/campaign-dashboard/segments/${id}`,
  },
}
