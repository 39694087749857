import { graphql } from '@/gql'

export const companiesQuery = graphql(`
  query Companies($search: String, $pagination: OffsetPaginationInput) {
    companies(search: $search, pagination: $pagination) {
      items {
        id
        slug
        name
        seatsInUse
        totalSeats
      }
      totalItemsCount
    }
  }
`)

export const companyDetailQuery = graphql(`
  query CompanyDetail($slug: String!) {
    companyStatistics(slug: $slug) {
      company {
        id
        name
        budgetPerEmployee
        registeredMembers
      }
      seatsInUse
      totalSeats
      totalBookedServices
      totalPendingBookings
      totalUsersFinishedScan
      totalPendingBudget
      totalSpendBudget
      totalAvailableBudget
      mostFavoriteThemes {
        score
        theme {
          id
          slug
          name
          color
          icon {
            url
          }
        }
      }
      privateEnergy
      themes {
        score
        theme {
          id
          slug
          name
          color
          icon {
            url
          }
        }
      }
      totalFinishedCheckin
      totalFinishedSelfscan
      workEnergy
    }
  }
`)

export const useInfoQuery = graphql(`
  query UserInfo($id: ID!) {
    userInfo(id: $id) {
      firstName
      lastName
      email
      companyMember {
        company {
          name
        }
        workEnergy
        privateEnergy
      }
      userThemes {
        ...ScoredThemeFragment
      }
      nonThemeAnswers {
        text
        answer
      }
    }
  }
`)

export const needSupportUsersQuery = graphql(`
  query NeedSupportUsers($pagination: OffsetPaginationInput) {
    needSupportUsers(pagination: $pagination) {
      items {
        id
        firstName
        lastName
        email
        companyMember {
          id
          company {
            name
          }
        }
      }
      totalItemsCount
    }
  }
`)

export const overviewStatisticsQuery = graphql(`
  query OverviewStatistics {
    overviewStatistics {
      totalPendingBookings
      bookedServices
      filteredBookings
      mostFavoriteThemes {
        score
        theme {
          id
          slug
          name
          color
          icon {
            url
          }
        }
      }
      privateEnergy
      registrations
      themes {
        score
        theme {
          id
          slug
          name
          color
          icon {
            url
          }
        }
      }
      totalFinishedCheckin
      totalFinishedSelfscan
      workEnergy
      totalSeats
      seatsInUse
      totalPendingBudget
      totalSpendBudget
      totalAvailableBudget
    }
  }
`)

export const overviewFilteredBookingsQuery = graphql(`
  query FilteredBookingsOverview($dateRange: StatisticsDateRange = QUARTER) {
    overviewStatistics(dateRange: $dateRange) {
      filteredBookings
    }
  }
`)

export const chatsWithNoAdminVendorQuery = graphql(`
  query VendorWithoutUserChats($pagination: OffsetPaginationInput) {
    vendorWithoutUserChats(pagination: $pagination) {
      totalItemsCount
      items {
        lastSent
        messages(pagination: { offset: 0, limit: 1 }) {
          items {
            content
          }
        }
        participant1 {
          name
          email
          id
        }
        participant2 {
          name
          email
          id
        }
      }
    }
  }
`)

export const notFollowedChatsQuery = graphql(`
  query NotFollowedChats($pagination: OffsetPaginationInput) {
    notFollowedChats(pagination: $pagination) {
      totalItemsCount
      items {
        lastSent
        messages(pagination: { offset: 0, limit: 1 }) {
          items {
            content
            sender {
              pk
            }
          }
        }
        participant1 {
          name
          email
          id
        }
        participant2 {
          name
          email
          id
        }
      }
    }
  }
`)

export const exportScansQuery = graphql(`
  query ExportScans($excludeIds: String, $pagination: OffsetPaginationInput) {
    exportScans(excludeIds: $excludeIds, pagination: $pagination) {
      items {
        userId
        organizationId
        scanType
        scoredQuestions {
          id
          name
          score
        }
        scoredThemes {
          id
          name
          score
        }
        date
        bookedProducts
      }
      totalItemsCount
    }
  }
`)
