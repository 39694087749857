import { OrganizationsCompanyAccountTypeEnum, UserType } from '@/gql/graphql'
import { create } from 'zustand'

export interface IUser extends UserType {
  roles?: UserRole[]
}

interface AuthStore {
  user: IUser | null
  accountType: OrganizationsCompanyAccountTypeEnum | undefined
  getOrgSlug: () => string
  setUser: (user: IUser) => void
  logout: () => void
  isStaff: () => boolean
}

export type UserRole =
  | 'company_owner'
  | 'company_admin'
  | 'company_employee'
  | 'vendor_member'
  | 'customer_support'
  | 'staff'

export const getUserRoles = (user: IUser): UserRole[] => {
  const roles: UserRole[] = []
  if (user.companyMember?.isAdmin) {
    roles.push('company_admin')
  }
  if (user.companyMember?.isOwner) {
    roles.push('company_owner')
  }
  if (user.companyMember?.isEmployee) {
    roles.push('company_employee')
  }
  if (user.vendorMember) {
    roles.push('vendor_member')
  }
  if (user.isCustomerSupport) {
    roles.push('customer_support')
  }
  if (user.isSuperuser || user.isStaff) {
    roles.push('staff')
  }

  return roles
}

const useAuthStore = create<AuthStore>((set, get) => ({
  user: null,
  accountType: undefined,
  getOrgSlug: () => {
    const orgType = localStorage.getItem('org-type')
    if (orgType === 'company') {
      return get().user?.companyMember?.company.slug || ''
    }

    if (orgType === 'vendor') {
      return get().user?.vendorMember?.vendor.slug || ''
    }

    if (get().user?.companyMember) {
      localStorage.setItem('org-type', 'company')
      return get().user?.companyMember?.company.slug || ''
    }

    if (get().user?.vendorMember) {
      localStorage.setItem('org-type', 'vendor')
      return get().user?.vendorMember?.vendor.slug || ''
    }

    return ''
  },
  isStaff: () => !!(get().user?.isStaff || get().user?.isSuperuser),
  setUser: (user: IUser) =>
    set({
      user: { ...user, roles: getUserRoles(user) },
      accountType: user.companyMember?.company?.accountType,
    }),
  logout: () => set({ user: null }),
}))

export default useAuthStore
