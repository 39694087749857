import { graphql } from '@/gql'

export const requestEmailLoginCode = graphql(`
  mutation requestEmailLoginCode($email: Email!) {
    requestEmailLoginCode(input: { email: $email })
  }
`)

export const requestTextLoginCode = graphql(`
  mutation requestTextLoginCode($emailCode: String!, $changePhoneNumber: PhoneNumber) {
    requestTextLoginCode(input: { emailCode: $emailCode, changePhoneNumber: $changePhoneNumber })
  }
`)

export const login = graphql(`
  mutation login($textCode: String!, $emailCode: String!) {
    login(input: { textCode: $textCode, emailCode: $emailCode }) {
      id
      email
      firstName
      lastName
      phoneNumber
      familyStatus
      gender
      dateOfBirth
      language
      companyMember {
        company {
          name
          slug
          budgetPerEmployee
        }
        isOwner
        isAdmin
        isEmployee
        budget
        totalBudget
        workEnergy
        privateEnergy
      }
      vendorMember {
        vendor {
          name
          slug
          smallImage(resize: { fill: { width: 80, height: 80 } }) {
            url
          }
        }
      }
      userThemes {
        id
      }
    }
  }
`)

export const logoutMutation = graphql(`
  mutation logout {
    logout
  }
`)

export const acceptTermsMutation = graphql(`
  mutation acceptTerms($input: AcceptTermsInput!) {
    acceptTerms(input: $input) {
      createdAt
      version
      content
      accepted
    }
  }
`)

export const fillRegisterInfoMutation = graphql(`
  mutation FillRegisterInfo($code: String!, $input: UserInput!) {
    fillInRegisterInfo(code: $code, input: $input) {
      ... on UserType {
        email
        phoneNumber
      }
    }
  }
`)
