import { AlertCircle } from 'lucide-react'

import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'

export function ErrorMessage({ message }: { message?: string }) {
  return (
    <Alert variant="destructive" className="m-auto my-4 w-full max-w-screen-lg">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>Error</AlertTitle>
      <AlertDescription>{message || 'An error has occurred.'}</AlertDescription>
    </Alert>
  )
}
