import { useMutationGraphQL, useQueryGraphQL } from '@/use-graphql'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { sharedInfoDetailQuery } from './graphql/query'
import { Button } from '@/components/ui/button'
import { approveRequestMutation, rejectRequestMutation } from './graphql/mutation'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { UsersSharedUserInfoStatusEnum } from '@/gql/graphql'
import { ErrorMessage } from "@/components/ErrorMessage"

const ShareRequest = () => {
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()
  const { slug = '' } = useParams()
  const { t } = useTranslation()
  const { data, error } = useQueryGraphQL(['shared-info-detail'], sharedInfoDetailQuery, { slug })
  const approveRequestMutate = useMutationGraphQL(approveRequestMutation, { slug })
  const rejectRequestMutate = useMutationGraphQL(rejectRequestMutation, { slug })

  const handleAccept = async () => {
    setLoading(true)
    await approveRequestMutate.mutateAsync()
    queryClient.invalidateQueries({ queryKey: ['shared-info-detail'] })
    setLoading(false)
  }
  const handleReject = async () => {
    setLoading(true)
    await rejectRequestMutate.mutateAsync()
    queryClient.invalidateQueries({ queryKey: ['shared-info-detail'] })
    setLoading(false)
  }

  if (error) {
    return <ErrorMessage message="You don't have permission to see this request" />
  }

  return (
    <div className="mx-auto mt-20 max-w-screen-md px-4">
      <div className="w-full rounded-lg border-t border-t-slate-100 bg-gradient-to-b from-secondary-aqua to-white p-20 text-center shadow-lg">
        <h1 className="font-playfair text-xl text-brand-torea">{t('title.share_info_request')}</h1>
        <p className="mt-4 max-w-lg mx-auto">{t('description.share_info_request', {vendors: data?.sharedUserInfo.vendors.map(e => e.name).join(', ')})}</p>
        {data?.sharedUserInfo.message && (
          <>
            <p className="mt-8">{t('label.message')}</p>
            <q className="mt-2 text-grey-black">{data.sharedUserInfo.message}</q>
          </>
        )}
        {data?.sharedUserInfo.status === UsersSharedUserInfoStatusEnum.Pending && (
          <div className="mx-auto mt-10 flex w-fit items-center gap-5">
            <Button variant="secondary" onClick={handleAccept} disabled={loading}>
              {t('button.approve')}
            </Button>
            <Button
              variant="outline"
              className="text-brand-valencia"
              onClick={handleReject}
              disabled={loading}
            >
              {t('button.reject')}
            </Button>
          </div>
        )}
        {data?.sharedUserInfo.status === UsersSharedUserInfoStatusEnum.Approved && (
          <p className="mt-10 text-brand-torea">{t('label.accepted_request')}</p>
        )}
        {data?.sharedUserInfo.status === UsersSharedUserInfoStatusEnum.Refused && (
          <p className="mt-10 text-brand-valencia">{t('label.rejected_request')}</p>
        )}
      </div>
    </div>
  )
}

export default ShareRequest
