const NLFlag = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 9 6">
      <rect fill="#21468B" width="9" height="6" />
      <rect fill="#FFF" width="9" height="4" />
      <rect fill="#AE1C28" width="9" height="2" />
    </svg>
  )
}

export default NLFlag
