'use client'

import * as React from 'react'
import { Check, ChevronsUpDown } from 'lucide-react'

import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { cn } from '@/utils/shadcn'

export default function ComboBox({
  options,
  itemText = 'item',
  className,
  triggerClassName,
  defaultValue,
  onChangeValue,
}: {
  options: { label: string; value: string }[]
  itemText?: string
  className?: string
  triggerClassName?: string
  defaultValue: string
  onChangeValue: (v: string) => void
}) {
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState(defaultValue)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn('w-full justify-between rounded-md px-3 text-gray-400', triggerClassName, {
            'text-brand-torea': !!value,
          })}
        >
          {value ? options.find((item) => item.value === value)?.label : `Select ${itemText} ...`}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn('w-96 p-2', className)} align="start">
        <Command>
          <CommandInput
            className="text-sm placeholder:text-gray-500"
            placeholder={`Search ${itemText}...`}
          />
          <CommandEmpty>No {itemText} found.</CommandEmpty>
          <CommandGroup>
            {options.map((item) => (
              <CommandItem
                key={item.value}
                value={item.label}
                onSelect={(currentValue) => {
                  const value = options.find(
                    (option) => option.label.toLowerCase() === currentValue,
                  )?.value
                  setValue(value ?? '')
                  setOpen(false)
                  onChangeValue(value ?? '')
                }}
                className="cursor-pointer p-2 hover:bg-brand-cream"
              >
                <Check
                  className={cn('mr-2 h-4 w-4', value === item.value ? 'opacity-100' : 'opacity-0')}
                />
                {item.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
