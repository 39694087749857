import { create } from 'zustand'

const useLoadingStore = create<{ isLoading: boolean; setLoading: (value: boolean) => void }>(
  (set) => ({
    isLoading: false,
    setLoading: (isLoading: boolean) => set({ isLoading }),
  }),
)

export default useLoadingStore
