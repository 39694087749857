import { useEffect } from 'react'
import useKeyPress from './useKeyPress'

const usePressEnter = (callback?: (input?: any) => void) => {
  const isPressEnter = useKeyPress('Enter')

  useEffect(() => {
    if (isPressEnter) {
      callback?.()
    }
  }, [isPressEnter])
}

export default usePressEnter
