import LoginImage from '@/assets/images/LoginImage.png'
import { ArrowUpRight, Check, Lock } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { validateEmail } from '@/utils/validations'
import { useMutationGraphQL } from '@/use-graphql'
import { requestEmailLoginCode } from './graphql/mutation'
import { toast } from 'react-toastify'
import usePressEnter from '@/hooks/usePressEnter'
import { transformErrorMessage } from '@/utils/handle-string'

const Login = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  usePressEnter(!success ? handleSubmit : undefined)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const requestEmailLoginCodeMutation = useMutationGraphQL(
    requestEmailLoginCode,
    { email },
    {
      onSuccess: () => setSuccess(true),
      onError: (error) => {
        // @ts-ignore
        toast.error(transformErrorMessage(error))
      },
    },
  )

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
    error && setError('')
  }

  function handleSubmit() {
    if (!validateEmail(email)) {
      setError(t('validation.invalid_email'))
      return
    }
    requestEmailLoginCodeMutation.mutate()
  }

  return (
    <div className="m-auto flex max-w-lg flex-col text-center md:mt-20 md:max-w-5xl md:flex-row md:justify-center md:gap-5">
      <div className="relative mt-6 h-[440px] max-w-full px-4 md:h-[545px] md:w-[440px]">
        <img
          src={LoginImage}
          className="h-full w-full rounded-[48px] object-cover brightness-95 filter"
        />
      </div>
      <div className="relative -mt-36 rounded-5xl bg-white px-5 py-8 md:mt-24 md:w-[440px] md:px-12">
        {!success ? (
          <>
            <Lock className="m-auto h-6 w-6 text-brand-cream md:h-8 md:w-8" />
            <div className="mb-5 p-4 md:mb-11">
              <h1 className="mb-3 font-playfair text-4xl tracking-wide text-brand-torea">
                {t('login.title')}
              </h1>
              <p className="text-primary">{t('login.description')}</p>
            </div>

            <Input
              label={t('label.email')}
              value={email}
              onChange={handleChangeEmail}
              error={error}
              ref={inputRef}
              type="email"
              inputMode="email"
              autoComplete="on"
            />

            <div className="mt-6 flex justify-end">
              <Button onClick={handleSubmit}>
                {t('button.login')} <ArrowUpRight className="ml-2" />
              </Button>
            </div>
          </>
        ) : (
          <>
            <Check className="m-auto h-6 w-6 text-brand-cream md:mt-16 md:h-8 md:w-8" />
            <div className="mb-5 p-4 md:mb-11">
              <h1 className="mb-3 font-playfair text-4xl tracking-wide text-brand-torea">
                {t('login.title')}
              </h1>
              <p className="text-primary">{t('login.success_message')}</p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Login
