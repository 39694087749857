import QueryBuilder from 'react-querybuilder'
import { fields } from '../fields'
import { SegmentType } from '@/gql/graphql'
import { ErrorBoundary } from '@sentry/react'

const SegmentItem = ({ segment }: { segment: SegmentType }) => {
  return (
    <div className="w-full cursor-pointer rounded-lg bg-brand-cream-white px-5 py-2 shadow hover:shadow-md hover:-translate-y-0.5 transition">
      <h2 className="mb-1 text-lg text-brand-torea">{segment.name}</h2>
      <p className="mb-1">
        <strong>{segment.contacts.totalItemsCount}</strong> contacts
      </p>
      <div className="hidden md:block origin-top-left">
        <ErrorBoundary fallback={<div />}>
          {/* @ts-ignore */}
          {segment.filters && <QueryBuilder fields={fields} query={JSON.parse(segment.filters)} disabled />}
        </ErrorBoundary>
      </div>
    </div>
  )
}

export default SegmentItem
