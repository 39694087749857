import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import SegmentList from './pages/SegmentList'
import MessageTemplateList from './pages/MessageTemplateList'
import CampaignList from './pages/CampaignList'

const CampaignDashboard = () => {
  return (
    <Tabs defaultValue="segments" className="container mt-4">
      <TabsList className="h-12 px-2">
        <TabsTrigger value="segments" className="px-10 text-base">
          Segments
        </TabsTrigger>
        <TabsTrigger value="campaigns" className="px-10 text-base">
          Campaigns
        </TabsTrigger>
        <TabsTrigger value="messages" className="px-10 text-base">
          Message Templates
        </TabsTrigger>
      </TabsList>
      <TabsContent value="segments">
        <SegmentList />
      </TabsContent>
      <TabsContent value="campaigns">
        <CampaignList />
      </TabsContent>
      <TabsContent value="messages">
        <MessageTemplateList />
      </TabsContent>
    </Tabs>
  )
}

export default CampaignDashboard
