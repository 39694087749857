import { Button } from '@/components/ui/button'
import { LoginMutation } from '@/gql/graphql'
import { cn } from '@/utils/shadcn'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  user: LoginMutation['login']
  close?: () => void
}

type OrgType = 'company' | 'vendor'

const OrganizationSelect = ({ user, close }: Props) => {
  const { t } = useTranslation()
  const [orgType, setOrgType] = useState<'company' | 'vendor'>('company')

  const handleSelect = (_orgType: OrgType) => {
    setOrgType(_orgType)
  }

  const handleSubmit = () => {
    localStorage.setItem('org-type', orgType)
    close?.()
  }

  return (
    <div>
      <div
        className={cn('mb-6 cursor-pointer rounded-md border p-6', {
          'bg-blue-500': orgType === 'company',
        })}
        onClick={() => handleSelect('company')}
      >
        <h3 className={cn('text-xl', { 'text-white': orgType === 'company' })}>
          {user?.companyMember?.company.name}
        </h3>
      </div>
      <div
        className={cn('mb-6 cursor-pointer rounded-md border p-6', {
          'bg-blue-500': orgType === 'vendor',
        })}
        onClick={() => handleSelect('vendor')}
      >
        <h3 className={cn('text-xl', { 'text-white': orgType === 'vendor' })}>
          {user?.vendorMember?.vendor.name}
        </h3>
      </div>
      <Button onClick={handleSubmit} className="ml-auto mt-8 flex">
        {t('button.save')}
      </Button>
    </div>
  )
}

export default OrganizationSelect
