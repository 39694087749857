import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import UKFlag from '@/assets/icons/UKFlag'
import NLFlag from '@/assets/icons/NLFlag'
import { FilterDropdown } from './FilterDropdown'
import { useMutationGraphQL } from '@/use-graphql'
import { updateLanguageMutation } from './navbar/graphql/mutation'
import useAuthStore from '@/stores/useAuthStore'
import { useQueryClient } from '@tanstack/react-query'

const languageOptions = [
  { code: 'en', flag: <UKFlag className="w-6" /> },
  { code: 'nl', flag: <NLFlag className="w-6" /> },
]

const LanguageSelect = () => {
  const queryClient = useQueryClient()
  const [value, setValue] = useState<string | undefined>(localStorage.getItem('language') || 'nl')
  const { i18n } = useTranslation()
  const { user } = useAuthStore()
  const updateLanguage = useMutationGraphQL(
    updateLanguageMutation,
    // @ts-ignore
    { language: value },
    { onSuccess: () => setTimeout(() => queryClient.invalidateQueries({ type: 'active' }), 500) },
  )
  useEffect(() => {
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', 'nl')
    }
    if (i18n.language && value && value !== i18n.language) {
      localStorage.setItem('language', value)
      i18n.changeLanguage(value)
    }
    if (user && user?.language !== value) {
      updateLanguage.mutate()
    }
  }, [i18n, value, user])

  return (
    <FilterDropdown
      popupClassName="sm:-ml-4 sm:mt-3"
      contentClassName="sm:max-h-[25vh]"
      showSearch={false}
      contentWidth={150}
      value={value}
      onChange={setValue}
      options={languageOptions.map((e) => ({
        value: e.code,
        label: (
          <div className="flex items-center gap-4">
            {e.flag}
            <span>{e.code.toUpperCase()}</span>
          </div>
        ),
      }))}
      customButton={languageOptions.find((e) => e.code === value)?.flag}
      hideCancel
      hideOnClick
      sideOffset={-10}
    />
  )
}

export default LanguageSelect
