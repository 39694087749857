import { graphql } from '@/gql'

export const chatContactsQuery = graphql(`
  query ChatContacts($pagination: OffsetPaginationInput, $filters: ChatFilter) {
    chats(pagination: $pagination, filters: $filters) {
      items {
        id
        participant1 {
          id
          name
          isCurrentUser
        }
        participant2 {
          id
          name
        }
        hasUnreadMessage
        messages(pagination: { limit: 1 }) {
          items {
            content
            readAt
            createdAt
            type
          }
        }
        flagged
        archived
      }
      totalItemsCount
    }
  }
`)

export const messagesQuery = graphql(`
  query Messages($chatId: ID!, $pagination: OffsetPaginationInput, $filters: MessageFilter, $search: String) {
    chat(pk: $chatId) {
      messages(pagination: $pagination, filters: $filters, search: $search) {
        items {
          id
          direction
          content
          createdAt
          type
          sentEmailAt
          readAt
          archived
          markedUnread
          flagged
          senderEmail
        }
        totalItemsCount
      }
    }
  }
`)

export const hasUnreadChatQuery = graphql(`
  query HasUnreadChat {
    hasUnreadChat {
      normal
      fromCustomerSupport
    }
  }
`)

export const bookingQuery = graphql(`
  query Booking($id: ID!) {
    booking(pk: $id) {
      status
    }
  }
`)

export const chatTemplateQuery = graphql(`
  query ChatTemplate {
    chatTemplates {
      content
      id
    }
  }
`)
