import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { ChevronDown, Users } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { routes } from '@/routes/utils'
import Request from '@/assets/icons/Request'
import StatisticsIcon from "@/assets/icons/StatisticsIcon"

const CompanyManageDropdown = () => {
  const { t } = useTranslation()
  return (
    <DropdownMenu key="company-management">
      <DropdownMenuTrigger asChild>
        <button className="flex items-center gap-2 outline-none">
          {t('menu.manage')} <ChevronDown className="text-grey-black" size={18} />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" sideOffset={10} className="w-56 p-3 text-deep-cove">
        <Link to={routes.manageSeats.getPath()}>
          <DropdownMenuItem className="flex items-center gap-2 py-4">
            <Users size={18} /> {t('label.users')}
          </DropdownMenuItem>
        </Link>
        <Link to={routes.manageRequests.getPath()}>
          <DropdownMenuItem className="flex items-center gap-2 py-4">
            <Request className="w-4.5 h-4"  /> {t('label.requests')}
          </DropdownMenuItem>
        </Link>
        <Link to={routes.companyStatistics.getPath()}>
          <DropdownMenuItem className="flex items-center gap-2 py-4">
            <StatisticsIcon className="w-4.5 h-4"  /> {t('label.statistics')}
          </DropdownMenuItem>
        </Link>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default CompanyManageDropdown
