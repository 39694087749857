import { create } from 'zustand'

export interface ModalChildProps {
  close?: () => void
}

export interface Modal {
  name: string
  Component: React.FC<ModalChildProps>
  header?: string | React.ReactNode
  isAlert?: boolean
  isConfirmDialog?: boolean
  callback?: () => void
  isFullPage?: boolean
  isCardStyle?: boolean
  maxWidth?: number
}

interface ModalStore {
  modals: Record<string, Modal>
  addModal: (modal: Modal) => void
  closeModal: (name: string) => void
  closeAllModal: () => void
}

const useModalStore = create<ModalStore>((set) => ({
  modals: {},
  addModal: (modal: Modal) =>
    set((state) => ({
      modals: { ...state.modals, [modal.name]: modal },
    })),
  closeModal: (name) =>
    set((state) => {
      delete state.modals[name]
      return { modals: { ...state.modals } }
    }),
  closeAllModal: () =>
    set(() => {
      return { modals: {} }
    }),
}))

export default useModalStore
