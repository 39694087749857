import type {
  Field,
  RuleValidator,
  ValidationResult,
} from 'react-querybuilder'
import { defaultOperators, toFullOption } from 'react-querybuilder'

const equalOperator = defaultOperators.filter((op) => op.name === '=')
const stringOperators = defaultOperators.filter((op) =>
  [
    '=',
    '!=',
    'doesNotBeginWith',
    'beginsWith',
    'doesNotEndWith',
    'endsWith',
    'doesNotContain',
    'contains',
    'in',
    'notIn',
  ].includes(op.name),
)
const slugOperators = defaultOperators.filter((op) => ['=', '!=', 'in', 'notIn'].includes(op.name))
const numberOperators = defaultOperators.filter((op) =>
  ['=', '!=', '>', '>=', '<', '<=', 'between', 'notBetween'].includes(op.name),
)
const dateOperators = defaultOperators.filter((op) =>
  ['>', '>=', '<', '<=', 'between', 'notBetween'].includes(op.name),
)

const validator: RuleValidator = (q): ValidationResult => ({
  valid: /^[0-9] ON [a-zA-Z0-9-_]+$/.test(q.value),
  reasons: ['Theme score should be in the format <score> ON <theme>. E.g. 3 ON sleep'],
});


export const fields = (
  [
    {
      name: 'email',
      label: 'Email',
      operators: stringOperators,
    },
    { name: 'age', label: 'Age', inputType: 'number', operators: numberOperators },
    {
      name: 'hasPhoneNumber',
      label: 'Has phone number?',
      valueEditorType: 'checkbox',
      operators: [],
      defaultValue: false,
    },
    {
      name: 'registered',
      label: 'Registered yet?',
      valueEditorType: 'checkbox',
      operators: [],
      defaultValue: false,
    },
    {
      name: 'gender',
      label: 'Gender',
      operators: equalOperator,
      valueEditorType: 'select',
      values: [
        { name: 'MALE', label: 'Male' },
        { name: 'FEMALE', label: 'Female' },
        { name: 'OTHER', label: 'Other' },
        { name: 'NOT_SAY', label: 'Not say' },
      ],
    },
    {
      name: 'familyStatus',
      label: 'Family status',
      operators: equalOperator,
      valueEditorType: 'select',
      values: [
        { name: 'SINGLE_PARENT', label: 'Single parent' },
        { name: 'SINGLE', label: 'Single' },
        { name: 'IN_RELATIONSHIP_WITH_CHILDREN', label: 'In relationship with children' },
        { name: 'IN_RELATIONSHIP_NO_CHILDREN', label: 'In relationship without children' },
      ],
    },
    {
      name: 'language',
      label: 'Language',
      operators: equalOperator,
      valueEditorType: 'select',
      values: [
        { name: 'en', label: 'English' },
        { name: 'nl', label: 'Dutch' },
      ],
    },
    {
      name: 'themes',
      label: 'Favourite Themes',
      placeholder: 'Enter theme slug',
      operators: slugOperators,
      datatype: 'themes-select',
    },
    {
      id: 'themeScore',
      name: 'themeScore',
      label: 'Theme Score',
      operators: numberOperators,
      placeholder: '<score> ON <theme>',
      validator,
    },
    {
      name: 'company',
      label: 'Company',
      placeholder: 'Enter company slug',
      operators: slugOperators,
      datatype: 'companies-select',
    },
    {
      name: 'budget',
      label: 'Budget',
      operators: numberOperators,
      inputType: 'number',
    },
    {
      name: 'role',
      label: 'Role',
      operators: equalOperator,
      valueEditorType: 'radio',
      values: [
        { name: 'isEmployee', label: 'Employee' },
        { name: 'isAdmin', label: 'Company Admin' },
        { name: 'isOwner', label: 'Company Owner' },
      ],
    },
    {
      name: 'completedSelfscan',
      label: 'Completed Selfscan',
      valueEditorType: 'checkbox',
      operators: [],
      defaultValue: false,
    },
    {
      name: 'lastCheckin',
      label: 'Last Checkin',
      operators: dateOperators,
      inputType: 'date',
    },
    {
      name: 'lastLogin',
      label: 'Last Login',
      operators: dateOperators,
      inputType: 'date',
    },
    {
      name: 'lastSms',
      label: 'Last SMS',
      operators: dateOperators,
      inputType: 'date',
    },
    {
      name: 'lastContact',
      label: 'Last contact with client success',
      operators: dateOperators,
      inputType: 'date',
    },
  ] satisfies Field[]
).map(toFullOption)
