import * as React from 'react'

import { cn } from '@/utils/shadcn'
import { Label } from './label'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: string
  inputClassName?: string
  icon?: React.ReactNode
  onEnter?: () => void
  hideLabel?: boolean
  backgroundColor?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { id, label, error, className, inputClassName, type, icon, onEnter, hideLabel, ...props },
    ref,
  ) => {
    // Handle key press event
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && onEnter) {
        onEnter()
      }
    }

    return (
      <div className={cn('grid w-full items-center gap-1.5', className)}>
        <div className="relative">
          {!hideLabel && (
            <Label
              htmlFor={id}
              className={
                label
                  ? 'absolute -top-[7px] left-3 z-[1] bg-white px-1 text-[10px] font-medium text-deep-cove/50 opacity-100'
                  : 'opacity-0'
              }
              style={props.backgroundColor ? { backgroundColor: props.backgroundColor } : {}}
            >
              {label || 'Input Label'}
            </Label>
          )}
          {icon && (
            <div className={cn('absolute -top-2 left-3', { 'top-3': hideLabel })}>{icon}</div>
          )}
          <input
            id={id}
            type={type}
            className={cn(
              'flex h-11 w-full rounded-md border border-deep-cove/10 bg-background p-3 text-sm font-medium text-primary  transition duration-300 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
              {
                '!border-brand-valencia': !!error,
                'pl-10': icon,
              },
              inputClassName,
            )}
            onKeyUp={handleKeyPress} // Add key press event listener
            ref={ref}
            style={props.backgroundColor ? { backgroundColor: props.backgroundColor } : {}}
            {...props}
          />
          {error && <Label className={cn('text-[10px] text-brand-valencia')}>{error}</Label>}
        </div>
      </div>
    )
  },
)
Input.displayName = 'Input'

export { Input }
