const Request = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none" className={className}>
      <path
        d="M13.8906 8.08984C13.9453 8.36328 14 8.66406 14 8.9375V11.125C14 12.1094 13.207 12.875 12.25 12.875H1.75C0.765625 12.875 0 12.1094 0 11.125V8.9375C0 8.66406 0.0273438 8.36328 0.0820312 8.08984L1.75 1.30859C1.85938 0.898438 2.21484 0.625 2.625 0.625H11.375C11.7578 0.625 12.1133 0.898438 12.2227 1.30859L13.8906 8.08984ZM2.95312 1.9375L1.55859 7.625H3.9375C4.18359 7.625 4.40234 7.78906 4.51172 8.00781L5.19531 9.375H8.77734L9.46094 8.00781C9.57031 7.78906 9.78906 7.625 10.0625 7.625H12.4141L11.0195 1.9375H2.95312ZM12.6875 11.125V8.9375H10.4453L9.76172 10.332C9.65234 10.5508 9.43359 10.6875 9.1875 10.6875H4.8125C4.53906 10.6875 4.32031 10.5508 4.21094 10.332L3.52734 8.9375H1.3125V11.125C1.3125 11.3711 1.50391 11.5625 1.75 11.5625H12.25C12.4688 11.5625 12.6875 11.3711 12.6875 11.125ZM5.03125 4.5625C4.64844 4.5625 4.375 4.28906 4.375 3.90625C4.375 3.55078 4.64844 3.25 5.03125 3.25H8.96875C9.32422 3.25 9.625 3.55078 9.625 3.90625C9.625 4.28906 9.32422 4.5625 8.96875 4.5625H5.03125ZM9.84375 6.75H4.15625C3.77344 6.75 3.5 6.47656 3.5 6.09375C3.5 5.73828 3.77344 5.4375 4.15625 5.4375H9.84375C10.1992 5.4375 10.5 5.73828 10.5 6.09375C10.5 6.47656 10.1992 6.75 9.84375 6.75Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Request
