import { motion } from 'framer-motion'
import { Outlet, useLocation } from 'react-router-dom'
import Navbar from '@/components/navbar/Navbar'
import Smiley from '@/components/smiley/Smiley'
import useAuthStore from '@/stores/useAuthStore'
import { useQueryGraphQL } from '@/use-graphql'
import { hasUnreadChatQuery } from '@/pages/messages/graphql/query'
import useMessageStore from '@/stores/useMessageStore'
import { routes } from '@/routes/utils'
import { cn } from '@/utils/shadcn'

const hiddenSmileyPaths = [
  routes.selfscan.getPath(),
  routes.checkIn.getPath(),
  routes.messages.getPath(),
]

const AuthLayout = () => {
  const { isStaff, user } = useAuthStore()
  const { activeChat } = useMessageStore()
  const { pathname } = useLocation()
  const hideSmiley = hiddenSmileyPaths.includes(pathname)

  const { data: hasUnreadChatData } = useQueryGraphQL(
    ['has-unread-chat'],
    hasUnreadChatQuery,
    {},
    { refetchInterval: 10000, enabled: !activeChat },
  )

  return (
    <div className={cn('overflow-x-clip')}>
      <Navbar hasUnreadChat={!!hasUnreadChatData?.hasUnreadChat.normal} />
      <motion.div
        initial={{ y: 25, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.4, ease: 'easeOut' }}
        className="min-h-screen pt-16 lg:pt-20"
      >
        <Outlet />
      </motion.div>
      {isStaff() || user?.isCustomerSupport ? null : (
        <Smiley
          hasUnreadChat={!!hasUnreadChatData?.hasUnreadChat.fromCustomerSupport}
          hidden={hideSmiley}
        />
      )}
    </div>
  )
}

export default AuthLayout
