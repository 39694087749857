import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <section className="relative z-10 h-screen bg-brand-torea/90 py-[120px]">
      <div className="container mx-auto mt-20">
        <div className="-mx-4 flex">
          <div className="w-full px-4">
            <div className="mx-auto max-w-[600px] text-center">
              <h2 className="text-[50px] font-bold leading-none text-white sm:text-[80px] md:text-[100px]">
                404
              </h2>
              <h4 className="mt-6 text-[22px] font-semibold leading-tight text-white">
                {t('title.page_not_found')}
              </h4>
              <Link
                to="/"
                className="mt-12 inline-block rounded-lg border border-white px-8 py-3 text-center text-base font-semibold capitalize text-white transition hover:bg-white hover:text-primary"
              >
                {t('menu.dashboard')}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute left-0 top-0 -z-10 flex h-full w-full items-center justify-between space-x-5 md:space-x-8 lg:space-x-14">
        <div className="h-full w-1/3 bg-gradient-to-t from-[#FFFFFF14] to-[#C4C4C400]"></div>
        <div className="flex h-full w-1/3">
          <div className="h-full w-1/2 bg-gradient-to-b from-[#FFFFFF14] to-[#C4C4C400]"></div>
          <div className="h-full w-1/2 bg-gradient-to-t from-[#FFFFFF14] to-[#C4C4C400]"></div>
        </div>
        <div className="h-full w-1/3 bg-gradient-to-b from-[#FFFFFF14] to-[#C4C4C400]"></div>
      </div>
    </section>
  )
}

export default NotFound
