import { motion } from 'framer-motion'
import { ArrowRight, X } from 'lucide-react'
import { Button } from '../ui/button'
import { useTranslation } from 'react-i18next'
import useSmileyStore from '@/stores/useSmileyStore'

const SmileyPopup = ({ setOpen }: { setOpen: (v: boolean) => void }) => {
  const { t } = useTranslation()
  const { setOpenPopup } = useSmileyStore()

  const handleOpenChat = () => {
    setOpen(true)
    setOpenPopup(false)
  }

  return (
    <motion.div
      initial={{ bottom: '-300px', opacity: 0 }}
      animate={{ bottom: 96, opacity: 1 }}
      exit={{ bottom: '-300px', opacity: 0 }}
      transition={{ duration: 0.3, type: 'spring' }}
      className="hidden lg:block fixed right-16 w-72 translate-y-6 rounded-xl bg-secondary-arapawa p-3 pr-10 md:right-[92px] md:w-[340px] md:translate-y-0"
    >
      <div className="flex w-full items-center gap-2">
        <p className="font-medium text-white">{t('label.next_step_question')}</p>
        <Button
          className="gap-3 bg-brand-cream px-4 py-2.5 text-brand-torea"
          variant="secondary"
          onClick={handleOpenChat}
        >
          {t('label.yes')} <ArrowRight />
        </Button>
      </div>
      <X
        className="absolute right-3 top-3 cursor-pointer text-brand-valencia hover:text-red-400"
        size={18}
        strokeWidth={3}
        onClick={() => setOpenPopup(false)}
      />
    </motion.div>
  )
}

export default SmileyPopup
