import { graphql } from '@/gql'

export const approveRequestMutation = graphql(`
  mutation ApproveRequest($slug: String!) {
    shareInfoApproval(input: { slug: $slug, status: APPROVED })
  }
`)

export const rejectRequestMutation = graphql(`
  mutation RejectRequest($slug: String!) {
    shareInfoApproval(input: { slug: $slug, status: REFUSED })
  }
`)
