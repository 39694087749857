import React, { Suspense } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import pMinDelay from 'p-min-delay'
import RequireAuth from './RequiredAuth'
import NotFound from '@/pages/NotFound'
import Login from '@/pages/login-flow/Login'
import VerifyCode from '@/pages/login-flow/VerifyCode'
import Register from '@/pages/login-flow/Register'
import Logout from '@/pages/login-flow/Logout'
import Layout from '@/layouts/Layout'
import Loading from '@/components/Loading'
import AuthLayout from '@/layouts/AuthLayout'
import UnauthorizedPage from '@/pages/login-flow/UnauthorizedPage'
import MessagePage from '@/pages/messages/MessagePage'
import ShareRequest from '@/pages/shared-info/ShareRequest'
import { routes } from './utils'
import CampaignDashboard from '@/pages/campaigns/CampaignDashboard'
import SegmentDetail from '@/pages/campaigns/pages/SegmentDetail'
import SegmentList from '@/pages/campaigns/pages/SegmentList'
import { OrganizationsCompanyAccountTypeEnum } from '@/gql/graphql'

const ThemePage = React.lazy(() => pMinDelay(import('@/pages/pdp-theme/ThemePage'), 400))
const ProductPageService = React.lazy(() =>
  pMinDelay(import('@/pages/pdp-service/ServicePage'), 400),
)
const Dashboard = React.lazy(() =>
  pMinDelay(import('@/pages/dashboard/employee/EmployeeDashboard'), 400),
)
const CompanyDashboard = React.lazy(() =>
  pMinDelay(import('@/pages/dashboard/company-admin/CompanyDashboard'), 400),
)
const VendorDashboard = React.lazy(() =>
  pMinDelay(import('@/pages/dashboard/vendor-admin/VendorDashboard'), 400),
)

const VendorPage = React.lazy(() => pMinDelay(import('@/pages/pdp-vendor/VendorPage'), 400))
const BookingPage = React.lazy(() => pMinDelay(import('@/pages/booking/BookingPage'), 400))
const BlogPage = React.lazy(() => pMinDelay(import('@/pages/blogs/BlogPage'), 400))
const GuidePage = React.lazy(() => pMinDelay(import('@/pages/guides/GuidePage'), 400))
const ProductPage = React.lazy(() => pMinDelay(import('@/pages/products/ProductPage'), 400))
const Profile = React.lazy(() => pMinDelay(import('@/pages/profile/Profile'), 400))
const SelfScan = React.lazy(() => pMinDelay(import('@/pages/scan/SelfScan'), 400))
const CheckIn = React.lazy(() => pMinDelay(import('@/pages/scan/CheckIn'), 400))

const SharedInfoDetail = React.lazy(() =>
  pMinDelay(import('@/pages/shared-info/SharedInfoDetail'), 400),
)
const BlogsPage = React.lazy(() => pMinDelay(import('@/pages/blogs/BlogsPage'), 400))
const GuidesPage = React.lazy(() => pMinDelay(import('@/pages/guides/GuidesPage'), 400))
const VendorsPage = React.lazy(() => pMinDelay(import('@/pages/vendors/VendorsPage'), 400))
const FavoritesPage = React.lazy(() => pMinDelay(import('@/pages/favorites/FavoritesPage'), 400))

const TagPage = React.lazy(() => pMinDelay(import('@/pages/tags/TagPage'), 400))
const ManageSeats = React.lazy(() =>
  pMinDelay(import('@/pages/dashboard/company-admin/ManageSeats'), 400),
)
const TeamOffer = React.lazy(() =>
  pMinDelay(import('@/pages/dashboard/company-admin/TeamOffer'), 400),
)
const MangeRequests = React.lazy(() =>
  pMinDelay(import('@/pages/dashboard/company-admin/ManageRequests'), 400),
)
const CompanyStatistics = React.lazy(() =>
  pMinDelay(import('@/pages/dashboard/company-admin/CompanyStatistics'), 400),
)
const CustomerSupportDashboard = React.lazy(() =>
  pMinDelay(import('@/pages/dashboard/customer-support/CustomerSupportDashboard'), 400),
)
const CompanyDetail = React.lazy(() =>
  pMinDelay(import('@/pages/dashboard/customer-support/CompanyDetail'), 400),
)
const NeedSupportInsight = React.lazy(() =>
  pMinDelay(import('@/pages/dashboard/customer-support/NeedSupportInsight'), 400),
)
const ManageBookings = React.lazy(() =>
  pMinDelay(import('@/pages/dashboard/thrive-admin/ManageBookings'), 400),
)
const SearchPage = React.lazy(() => pMinDelay(import('@/pages/search/SearchPage'), 400))

const AnimatedRoutes = () => {
  const location = useLocation()
  return (
    <AnimatePresence mode="wait">
      <Suspense fallback={<Loading />}>
        <Routes key={location.pathname}>
          <Route path="/">
            <Route element={<Layout />}>
              {/* public routes */}
              <Route path={routes.requestCode.route} element={<Login />} />
              <Route path={routes.logout.route} element={<Logout />} />
              <Route path={routes.login.route} element={<VerifyCode />} />
              <Route path={routes.register.route} element={<Register />} />
              <Route path={routes.unauthorized.route} element={<UnauthorizedPage />} />
            </Route>

            <Route element={<RequireAuth />}>
              <Route element={<AuthLayout />}>
                <Route path={routes.profile.route} element={<Profile />} />
                <Route path={routes.messages.route} element={<MessagePage />} />
              </Route>
            </Route>

            {/* Company Employee Routes */}
            <Route
              element={
                <RequireAuth
                  allowedRoles={['company_employee', 'company_owner', 'company_admin']}
                />
              }
            >
              <Route element={<AuthLayout />}>
                <Route path="/" element={<Dashboard />} />
                <Route path={routes.theme.route} element={<ThemePage />} />
                <Route
                  element={
                    <RequireAuth
                      requiredAccountType={[
                        OrganizationsCompanyAccountTypeEnum.Normal,
                        OrganizationsCompanyAccountTypeEnum.Free,
                      ]}
                    />
                  }
                >
                  <Route path={routes.vendors.route} element={<VendorsPage />} />
                  <Route path={routes.checkIn.route} element={<CheckIn />} />
                </Route>
                <Route path={routes.blogs.route} element={<BlogsPage />} />
                <Route path={routes.blogDetail.route} element={<BlogPage />} />
                <Route path={routes.guides.route} element={<GuidesPage />} />
                <Route path={routes.guideDetail.route} element={<GuidePage />} />
                <Route path={routes.booking.route} element={<BookingPage />} />
                <Route path={routes.selfscan.route} element={<SelfScan />} />
                <Route path={routes.favorites.route} element={<FavoritesPage />} />
                <Route path={routes.infoRequest.route} element={<ShareRequest />} />
                <Route path={routes.tagPage.route} element={<TagPage />} />
                <Route path={routes.searchPage.route} element={<SearchPage />} />
              </Route>
            </Route>

            {/* Vendor and Product view */}
            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    'company_employee',
                    'company_owner',
                    'company_admin',
                    'vendor_member',
                  ]}
                  requiredAccountType={[
                    OrganizationsCompanyAccountTypeEnum.Normal,
                    OrganizationsCompanyAccountTypeEnum.Free,
                  ]}
                />
              }
            >
              <Route element={<AuthLayout />}>
                <Route path={routes.vendorDetail.route} element={<VendorPage />} />
                <Route path={routes.serviceDetail.route} element={<ProductPageService />} />
                <Route path={routes.productDetail.route} element={<ProductPage />} />
              </Route>
            </Route>

            {/* Company Admin Routes */}
            <Route element={<RequireAuth allowedRoles={['company_admin', 'company_owner']} />}>
              <Route element={<AuthLayout />}>
                <Route path={routes.companyDashboard.route} element={<CompanyDashboard />} />
                <Route path={routes.manageSeats.route} element={<ManageSeats />} />
                <Route path={routes.teamOffer.route} element={<TeamOffer />} />
                <Route path={routes.manageRequests.route} element={<MangeRequests />} />
                <Route path={routes.companyStatistics.route} element={<CompanyStatistics />} />
              </Route>
            </Route>

            {/* Vendor member Routes */}
            <Route element={<RequireAuth allowedRoles={['vendor_member']} />}>
              <Route element={<AuthLayout />}>
                <Route path={routes.vendorDashboard.route} element={<VendorDashboard />} />
                <Route path={routes.sharedInfoDetail.route} element={<SharedInfoDetail />} />
              </Route>
            </Route>

            {/* Customer support */}
            <Route element={<RequireAuth allowedRoles={['customer_support', 'staff']} />}>
              <Route element={<AuthLayout />}>
                <Route
                  path={routes.customerDashboard.route}
                  element={<CustomerSupportDashboard />}
                />
                <Route path={routes.company.route} element={<CompanyDetail />} />
                <Route path={routes.userInfoDetail.route} element={<NeedSupportInsight />} />
                <Route path={routes.bookings.route} element={<ManageBookings />} />
              </Route>
            </Route>

            <Route element={<RequireAuth allowedRoles={['staff']} />}>
              <Route element={<AuthLayout />}>
                <Route path={routes.campaignDashboard.route} element={<CampaignDashboard />} />
                <Route path={routes.segments.route} element={<SegmentList />} />
                <Route path={routes.segmentDetail.route} element={<SegmentDetail />} />
              </Route>
            </Route>

            {/* catch all */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </AnimatePresence>
  )
}

export default AnimatedRoutes
