import { Button } from '@/components/ui/button'
import useModalStore from '@/stores/useModalStore'
import { Mail } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { sendEmailByAdminMutation } from '../../graphql/mutation'
import { useMutationGraphQL } from '@/use-graphql'

const SendEmailButton = ({ id }: { id: string }) => {
  const { addModal } = useModalStore()
  const { t } = useTranslation()
  const sendEmailMutate = useMutationGraphQL(sendEmailByAdminMutation, { id })

  const showConfirmModal = () => {
    addModal({
      name: 'send-email-notification',
      Component: ({ close }) => (
        <div className="p-10">
          <h3>Do you want to send email for this message to user?</h3>
          <div className="mt-10 flex justify-end gap-4">
            <Button
              onClick={() => {
                sendEmailMutate.mutateAsync()
                close?.()
              }}
            >
              {t('button.accept')}
            </Button>
            <Button variant="outline" onClick={close}>
              {t('button.cancel')}
            </Button>
          </div>
        </div>
      ),
      isCardStyle: true,
      maxWidth: 700,
    })
  }

  return (
    <button
      onClick={showConfirmModal}
      className="absolute top-2 right-1 rounded-full border border-brand-torea bg-white p-1 text-brand-torea duration-200 hover:bg-secondary-arapawa hover:text-white"
    >
      <Mail size={14} />
    </button>
  )
}

export default SendEmailButton
