import { MultiSelect } from '@/components/ui/multi-select'
import { companiesQuery } from '@/pages/dashboard/customer-support/graphql/query'
import { useQueryGraphQL } from '@/use-graphql'
import { SetStateAction } from 'react'

const CompaniesSelect = ({
  value,
  onChange,
}: {
  value: string[]
  onChange: (values: SetStateAction<string[]>) => void
}) => {
  const { data } = useQueryGraphQL(
    ['companies'],
    companiesQuery,
    {pagination: {limit: 100}},
    { staleTime: 30 * 60 * 1000, cacheTime: 60 * 60 * 1000 },
  )

  return (
    <MultiSelect
      options={
        data?.companies.items.map((company) => ({ label: company.name, value: company.slug })) || []
      }
      selected={value}
      onChange={onChange}
    />
  )
}

export default CompaniesSelect
