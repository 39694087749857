import { useQueryGraphQL } from '@/use-graphql'
import { chatTemplateQuery } from '../graphql/query'
import { useState } from 'react'
import { Popover } from '@radix-ui/react-popover'
import { PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { MessageSquareQuote } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Tooltip } from '@/components/ToolTip'

const ChatTemplateButton = ({
  setMessage,
  receiver,
}: {
  setMessage: (v: string) => void
  receiver: string
}) => {
  const { data } = useQueryGraphQL(
    ['chat-templates'],
    chatTemplateQuery,
    {},
    {
      staleTime: 1000 * 60 * 60, // 1 hour
    },
  )
  const [open, setOpen] = useState(false)

  const templates = data?.chatTemplates || []

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="mx-2">
        <Tooltip
          content="Use chat templates"
          delayDuration={100}
          className="bg-slate-800/70 text-xs text-white"
        >
          <Button size="icon" variant="ghost">
            <MessageSquareQuote className="text-brand-torea" />
          </Button>
        </Tooltip>
      </PopoverTrigger>
      <PopoverContent className="z-[999] w-96 px-4 py-6" sideOffset={16} align="end">
        <div className="space-y-4">
          {templates.map((template) => (
            <button
              key={template.id}
              className="block rounded-lg border p-4 py-2 text-left shadow-sm hover:bg-brand-torea-medium/10 hover:shadow-md"
              onClick={() => {
                setOpen(false)
                setMessage(template.content.replace('{user_first_name}', receiver.split(' ')[0]))
              }}
            >
              {template.content}
            </button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default ChatTemplateButton
