import { MessageCircle, MessageSquareDashed } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import ContactItem from './ContactItem'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import { useEffect, useState } from 'react'
import useMessageStore from '@/stores/useMessageStore'
import { getNextPageParam, useInfiniteQueryGraphQL } from '@/use-graphql'
import { chatContactsQuery } from '../graphql/query'
import { Button } from '@/components/ui/button'
import { getParticipant } from '@/utils/message'
import { ErrorBoundary } from '@sentry/react'
import { ErrorMessage } from '@/components/ErrorMessage'
import { useSearchParams } from 'react-router-dom'
import SelectInput from '@/components/SelectInput'
import useAuthStore from '@/stores/useAuthStore'

export const CONTACT_LIMIT = 10

const ContactList = () => {
  const [searchParams] = useSearchParams()
  const chatId = searchParams.get('chatId')
  const user = searchParams.get('user')
  const { user: authUser, isStaff } = useAuthStore()
  const { t } = useTranslation()
  const { activeChat, setActiveChat } = useMessageStore()
  const { width } = useWindowDimensions()
  const [filter, setFilter] = useState('none')
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQueryGraphQL(
    ['chat-contacts'],
    chatContactsQuery,
    {
      pagination: { limit: CONTACT_LIMIT },
      filters: filter !== 'none' ? { [filter]: true } : undefined,
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return getNextPageParam({
          allPages,
          limit: CONTACT_LIMIT,
          totalCount: lastPage?.chats.totalItemsCount,
        })
      },
      refetchInterval: 6000,
    },
  )

  useEffect(() => {
    if (chatId) {
      setActiveChat({ id: chatId, name: user || '', image: '' })
    }
  }, [chatId, user])

  useEffect(() => {
    if (width > 768 && !activeChat && data) {
      const item = data.pages[0].chats.items[0]
      if (item) {
        const participant = getParticipant(item)
        setActiveChat({ id: item.id, name: participant.name, image: '' })
      }
    }
  }, [data, setActiveChat, width])

  return (
    <ErrorBoundary fallback={<ErrorMessage />}>
      <div className="h-full w-full overflow-auto px-4 pb-6 pt-2.5 md:w-[300px] md:border-r md:px-5 lg:w-[400px] xl:w-[480px]">
        <div className="w-full">
          <MessageCircle className="mx-auto text-brand-valencia" />
          <h1 className="text-center text-3xl tracking-wide text-brand-torea">
            {t('title.messages')}
          </h1>
          {(authUser?.isCustomerSupport || isStaff()) && (
            <SelectInput
              className="ml-auto w-40"
              label="Filter"
              value={filter}
              onChange={setFilter}
              options={[
                { label: 'None', value: 'none' },
                { label: 'Flagged', value: 'flagged' },
                { label: 'Archived', value: 'archived' },
              ]}
            />
          )}
          <div className="mt-5 flex flex-col gap-6 md:gap-3">
            {!isLoading && !data?.pages?.[0].chats.totalItemsCount ? (
              <div className="mt-4 flex items-center justify-center">
                <MessageSquareDashed className="mr-4 text-gray-600" size={30} strokeWidth={2} />
                <h1 className="text-center text-2xl text-gray-600">{t('title.no_chats')}</h1>
              </div>
            ) : (
              data?.pages.map((page) =>
                page.chats.items.map((e) => (
                  <ContactItem
                    chatId={e.id}
                    name={getParticipant(e).name}
                    image={''} // TODO: use avatar url here.
                    key={e.id}
                    unread={activeChat?.id !== e.id && e.hasUnreadMessage}
                    date={e.messages.items[0]?.createdAt}
                    latestMessage={e.messages.items[0]?.content}
                    flagged={e.flagged}
                    archived={e.archived}
                  />
                )),
              )
            )}
          </div>
          {hasNextPage && (
            <div className="mt-8 w-full text-center">
              <Button onClick={() => fetchNextPage()}>{t('button.load_more')}</Button>
            </div>
          )}
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default ContactList
