import { graphql } from '@/gql'

export const themesQuery = graphql(`
  query ThemesQuery {
    themes {
      id
      name
      slug
      icon {
        url
      }
      highlights {
        highlightedItem {
          ... on ProductType {
            id
            __typename
            child {
              ... on ItemType {
                id
                __typename
              }
              ... on ServiceType {
                id
                __typename
              }
            }
          }
          ... on VendorType {
            id
            __typename
          }
          ... on ContentPageType {
            id
            __typename
            child {
              ... on BlogType {
                id
                __typename
              }
              ... on GuideType {
                id
                __typename
              }
            }
          }
        }
      }
    }
  }
`)

export const tagPagesQuery = graphql(`
  query TagPages {
    tagPages {
      id
      icon {
        url
      }
      tag {
        slug
        name
      }
    }
  }
`)
