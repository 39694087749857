import { cn } from '@/utils/shadcn'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select'
import { Label } from './ui/label'
import { ReactNode } from 'react'

interface Props {
  value: any
  onChange: (value: any) => void
  options: { label: ReactNode; value: string }[]
  label?: string
  placeholder?: string
  className?: string
}

const SelectInput = ({ value, onChange, label, placeholder, className, options }: Props) => {
  return (
    <div className={cn('w-full', className)}>
      <Label className="mb-1 block text-left">{label}</Label>
      <Select onValueChange={onChange} value={value}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className="z-[999]">
          <SelectGroup>
            {options.map((option) => (
              <SelectItem value={option.value} key={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  )
}

export default SelectInput
