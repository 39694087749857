import { SearchQueryQuery, SearchSearchItemCategoryEnum } from '@/gql/graphql'
import { routes } from '@/routes/utils'
import { getInfoFromSearchTextResult } from '@/utils/handle-string'
import { Link } from 'react-router-dom'
import { getHighlightedText } from './SearchPopup'
import useAuthStore from '@/stores/useAuthStore'

const BlogSearchResult = ({
  item,
  debounceSearch,
}: {
  item: SearchQueryQuery['search']['items'][0]
  debounceSearch: string
}) => {
  const { user } = useAuthStore()
  const { slug, title } = getInfoFromSearchTextResult(
    user?.language == 'en' ? item?.textEn : item?.text,
  )

  return (
    <Link
      to={
        item.category === SearchSearchItemCategoryEnum.Blog
          ? routes.blogDetail.getPath(slug)
          : routes.guideDetail.getPath(slug)
      }
      className="block truncate px-5 py-2 text-sm hover:bg-deep-cove/5"
    >
      {getHighlightedText(title, debounceSearch)}
    </Link>
  )
}

export default BlogSearchResult
