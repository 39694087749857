import { useQueryGraphQL } from '@/use-graphql'
import { messageTemplatesQuery } from '../graphql/query'
import { Button } from '@/components/ui/button'
import { FilePlus2 } from 'lucide-react'
import useModalStore from '@/stores/useModalStore'
import MessageTemplateForm from '../components/MessageTemplateForm'
import { MessageTemplatesQuery } from '@/gql/graphql'
import { Input } from '@/components/ui/input'
import { useState } from 'react'
import useDebounceValue from '@/hooks/useDebounceValue'

export type MessageTemplate = MessageTemplatesQuery['campaignMessageTemplates']['items'][0]

const MessageTemplateList = ({ hideAddButton }: { hideAddButton?: boolean }) => {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounceValue(search, 400)
  const { data, refetch } = useQueryGraphQL(['message-templates'], messageTemplatesQuery, {
    pagination: { limit: 99 },
    search: debouncedSearch,
  })
  const { addModal } = useModalStore()

  const showModal = (data?: MessageTemplate) => {
    addModal({
      name: 'message-template',
      Component: ({ close }) => <MessageTemplateForm close={close} refetch={refetch} data={data} />,
    })
  }
  const regex = /@\[\{\{(\w+)\}\}\]\(\w+\)/g

  return (
    <div>
      {!hideAddButton && (
        <Button size="sm" variant="outline" className="mt-1" onClick={() => showModal()}>
          <FilePlus2 className="text-brand-torea" size={20} />
          Add Message Template
        </Button>
      )}
      <Input
        className="mt-4"
        placeholder="Search message templates..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className="grid gap-4 md:grid-cols-2">
        {data?.campaignMessageTemplates.items.map((messageTemplate) => (
          <div
            key={messageTemplate.id}
            className="mt-4 cursor-pointer rounded border border-gray-200 p-3 hover:bg-gray-100 hover:shadow-md"
            onClick={() => showModal(messageTemplate)}
          >
            <h2 className="text-lg font-bold text-brand-torea">{messageTemplate.name}</h2>
            <p className="mt-2 line-clamp-3 text-sm">
              {messageTemplate.content.replace(regex, '{{$1}}')}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MessageTemplateList
