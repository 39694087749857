import { RefObject, useState } from 'react'
import { Send } from 'lucide-react'
import TextareaAutosize from 'react-textarea-autosize'
import { produce } from 'immer'

import { Button } from '@/components/ui/button'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import useMessageStore from '@/stores/useMessageStore'
import { useQueryClient } from '@tanstack/react-query'
import { useMutationGraphQL } from '@/use-graphql'
import { sendEmailMutation, sendMessageMutation } from '../graphql/mutation'
import { useTranslation } from 'react-i18next'
import { MESSAGE_LIMIT } from './MessageList'
import { ErrorBoundary } from '@sentry/react'
import { ErrorMessage } from '@/components/ErrorMessage'
import { cn } from '@/utils/shadcn'
import { add, isBefore } from 'date-fns'
import useAuthStore from '@/stores/useAuthStore'
import ChatTemplateButton from './ChatTemplateButton'

const MessageInput = ({
  messageListRef,
  className,
}: {
  messageListRef: RefObject<HTMLDivElement>
  className?: string
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { width } = useWindowDimensions()
  const { isStaff, user } = useAuthStore()
  const { activeChat } = useMessageStore()
  const [message, setMessage] = useState('')
  const [messageIdForEmail, setMessageIdForEmail] = useState('')
  const sendMessageMutate = useMutationGraphQL(
    sendMessageMutation,
    {
      input: { chatId: activeChat?.id || '', content: message },
    },
    {
      onSuccess: (data: any) => {
        setMessageIdForEmail(data.sendMessage.id)
        if (isStaff() || user?.isCustomerSupport) return
        setTimeout(() => sendEmailNotification(), 0)
      },
    },
  )

  const sendEmailMutate = useMutationGraphQL(sendEmailMutation, {
    id: messageIdForEmail,
  })

  function sendEmailNotification() {
    const sentAt = localStorage.getItem(`sent_email_for_chat_${activeChat?.id}`)
    if (!sentAt || isBefore(add(new Date(sentAt), { minutes: 15 }), new Date())) {
      sendEmailMutate.mutateAsync()
      localStorage.setItem(`sent_email_for_chat_${activeChat?.id}`, new Date().toISOString())
    }
  }

  function handleSendMessage() {
    if (activeChat?.id && message) {
      const messagesQueryKey = [
        'messages',
        {
          chatId: activeChat.id,
          pagination: {
            limit: MESSAGE_LIMIT,
          },
        },
      ]
      sendMessageMutate.mutate()
      queryClient.cancelQueries({ queryKey: messagesQueryKey })
      queryClient.setQueryData(messagesQueryKey, (oldData: any) => {
        return produce(oldData, (draftState: any) => {
          draftState?.pages?.[0]?.chat?.messages?.items?.unshift({
            id: new Date().toISOString(),
            content: message,
            createdAt: new Date().toISOString(),
            direction: 'MessageDirection.OUT',
          })
        })
      })

      setTimeout(() => {
        setMessage('')
        if (messageListRef.current) {
          messageListRef.current.scrollTo({
            top: messageListRef.current.scrollHeight + 1000,
            behavior: 'smooth',
          })
        }
      }, 0)
    }
  }

  function handleEnter(e: any) {
    if (!e.shiftKey && e.key === 'Enter') {
      handleSendMessage()
    }
  }

  if (width > 768) {
    return (
      <ErrorBoundary fallback={<ErrorMessage />}>
        <div
          className={cn(
            'hidden min-h-[72px] items-center rounded-[36px] border bg-white px-5 py-3 md:flex',
            className,
          )}
        >
          <TextareaAutosize
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            autoFocus
            maxRows={10}
            className="flex-1 resize-none !bg-white outline-none"
            placeholder={t('placeholder.type_message')}
            onKeyUp={handleEnter}
            disabled={activeChat?.name === 'System'}
          />
          {(isStaff() || user?.isCustomerSupport) && (
            <ChatTemplateButton setMessage={setMessage} receiver={activeChat?.name || ''} />
          )}
          <Button size="icon" onClick={handleSendMessage} disabled={activeChat?.name === 'System'}>
            <Send className="text-white" />
          </Button>
        </div>
      </ErrorBoundary>
    )
  }
  return (
    <ErrorBoundary fallback={<ErrorMessage />}>
      <div
        className={cn(
          'flex min-h-[48px] w-full shrink-0 items-center border-t bg-white px-4',
          className,
        )}
      >
        <TextareaAutosize
          className="flex-1 resize-none outline-none"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={t('placeholder.type_message')}
          maxRows={5}
          disabled={activeChat?.name === 'System'}
        />
        {(isStaff() || user?.isCustomerSupport) && (
          <ChatTemplateButton setMessage={setMessage} receiver={activeChat?.name || ''} />
        )}
        <Button size="icon" onClick={handleSendMessage} disabled={activeChat?.name === 'System'}>
          <Send className="text-white" size={16} />
        </Button>
      </div>
    </ErrorBoundary>
  )
}

export default MessageInput
