const env = Object.freeze({
  baseUrl: import.meta.env.VITE_BASE_URL,
  graphqlUrl: `${import.meta.env.VITE_BASE_URL}/graphql/`,
  sentryDns: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENV,
  useNewLocationFilter: import.meta.env.VITE_USE_NEW_LOCATION_FILTER,
  sendgridCode: import.meta.env.VITE_SENDGRID_CODE,
})

export default env
