import { Button } from '@/components/ui/button'
import useModalStore from '@/stores/useModalStore'
import { Loader2, Trash2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { updateMessage } from '../../graphql/mutation'
import { useMutationGraphQL } from '@/use-graphql'
import { Tooltip } from '@/components/ToolTip'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { toast } from 'react-toastify'

const ArchiveMessageButton = ({ id, archived }: { id: string; archived: boolean }) => {
  const queryClient = useQueryClient()
  const { addModal } = useModalStore()
  const { t } = useTranslation()
  const updateArchivedStateOfMessage = useMutationGraphQL(
    updateMessage,
    { id, input: { archived: !archived } },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['messages'] })
      },
      onError: () => {
        toast.error('Failed to archive message')
      },
    },
  )

  const showConfirmModal = () => {
    addModal({
      name: 'send-email-notification',
      Component: ({ close }) => {
          const [loading, setLoading] = useState(false)
          return (
          <div className="p-10">
            <h3>Do you want to {archived ? 'unarchive' : 'archive'} this message?</h3>
            <div className="mt-10 flex justify-end gap-4">
              <Button
                onClick={async () => {
                  try{
                    setLoading(true)
                    await updateArchivedStateOfMessage.mutateAsync()
                    setTimeout(() => close?.(), 300)
                  } finally {
                    setLoading(false)
                  }
                }}
                disabled={loading}
                className="relative"
              >
                {t('button.accept')}
                {loading && <Loader2 className="absolute left-2 top-3 animate-spin !text-white" size={20} />}
              </Button>
              <Button variant="outline" onClick={close}>
                {t('button.cancel')}
              </Button>
            </div>
          </div>
        )
      },
      isCardStyle: true,
      maxWidth: 700,
    })
  }

  return (
    <button onClick={showConfirmModal} className="hover:text-brand-valencia">
      <Tooltip
        content={archived ? 'Unarchive' : 'Archive'}
        delayDuration={100}
        className="bg-slate-800/70 text-xs text-white"
      >
        <Trash2 className={archived ? 'fill-current' : ''} size={20} />
      </Tooltip>
    </button>
  )
}

export default ArchiveMessageButton
