import Avatar from '@/components/Avatar'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card'
import useAuthStore from '@/stores/useAuthStore'
import useMessageStore from '@/stores/useMessageStore'
import { formatDateTimeForMessage } from '@/utils/datetime'
import { cn } from '@/utils/shadcn'
import ArchiveChatButton from './components/ArchiveChatButton'
import FlagChatButton from './components/FlagChatButton'
import { Flag } from 'lucide-react'

export const ContactAvatar = ({
  isActive,
  name,
  image,
}: {
  isActive?: boolean
  name: string
  image: string
}) => {
  return (
    <div className="flex items-center gap-3">
      <Avatar
        className={cn('h-[42px] w-[42px] md:h-12 md:w-12 lg:h-16 lg:w-16', {
          'md:border md:border-white': isActive,
        })}
        name={name}
        src={image}
      />
      <div>
        <p>{name.split('__')[0]}</p>
        <p className="text-sm font-medium">{name.split('__')[1]}</p>
      </div>
    </div>
  )
}

const ContactItem = ({
  unread,
  chatId,
  name,
  image,
  date,
  latestMessage,
  flagged,
  archived,
}: {
  unread?: boolean
  chatId: string
  name: string
  image: string
  date: string
  latestMessage: string
  flagged: boolean
  archived: boolean
}) => {
  const { activeChat, setActiveChat } = useMessageStore()
  const { isStaff, user } = useAuthStore()
  const isStaffOrCS = isStaff() || user?.isCustomerSupport

  if (isStaffOrCS) {
    return (
      <HoverCard openDelay={100} closeDelay={100} key={chatId}>
        <HoverCardTrigger>
          <button
            className={cn(
              'relative w-full cursor-pointer select-none border-b px-0 py-3 text-left md:px-3 lg:px-5 lg:py-5',
              {
                'md:rounded-2xl md:bg-brand-torea md:text-white': chatId === activeChat?.id,
              },
            )}
            onClick={() => setActiveChat({ id: chatId, name, image })}
          >
            <div className="flex items-start justify-between md:flex-col lg:flex-row lg:items-end">
              {isStaffOrCS && flagged && (
                <Flag
                  className={'absolute right-0 top-8 fill-current text-brand-valencia md:top-2'}
                  size={20}
                />
              )}
              <ContactAvatar isActive={chatId === activeChat?.id} name={name} image={image} />
              <div className="flex items-center gap-1 whitespace-nowrap text-sm md:ml-auto md:gap-2 lg:ml-0">
                <span>{date && formatDateTimeForMessage(new Date(date))}</span>
                {unread && <div className="h-4 w-4 rounded-full bg-brand-valencia" />}
              </div>
            </div>
            <p
              className={cn('mt-4.5 truncate text-grey-dark md:mt-1 lg:mt-4.5 lg:pr-6', {
                'md:text-white': chatId === activeChat?.id,
              })}
            >
              {latestMessage}
            </p>
          </button>
        </HoverCardTrigger>
        <HoverCardContent
          side="top"
          align="end"
          sideOffset={-20}
          className="w-fit bg-white/75 backdrop-blur-sm"
        >
          <div className="flex cursor-pointer items-center gap-4 text-brand-torea">
            <ArchiveChatButton id={chatId} archived={archived} />
            <FlagChatButton id={chatId} flagged={flagged} />
          </div>
        </HoverCardContent>
      </HoverCard>
    )
  }

  return (
    <div
      className={cn(
        'w-full cursor-pointer select-none border-b px-0 py-3 md:px-3 lg:px-5 lg:py-5',
        {
          'md:rounded-2xl md:bg-brand-torea md:text-white': chatId === activeChat?.id,
        },
      )}
      onClick={() => setActiveChat({ id: chatId, name, image })}
    >
      <div className="flex items-start justify-between md:flex-col lg:flex-row lg:items-end">
        <ContactAvatar isActive={chatId === activeChat?.id} name={name} image={image} />
        <div className="flex items-center gap-1 whitespace-nowrap text-sm md:ml-auto md:gap-2 lg:ml-0">
          <span>{date && formatDateTimeForMessage(new Date(date))}</span>
          {unread && <div className="h-4 w-4 rounded-full bg-brand-valencia" />}
        </div>
      </div>
      <p
        className={cn('mt-4.5 truncate text-grey-dark md:mt-1 lg:mt-4.5 lg:pr-6', {
          'md:text-white': chatId === activeChat?.id,
        })}
      >
        {latestMessage}
      </p>
    </div>
  )
}

export default ContactItem
