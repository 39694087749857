import { Sheet, SheetClose, SheetContent, SheetFooter, SheetTrigger } from '../ui/sheet'
import ThemeLogo from '../theme-logo/ThemeLogo'
import colors from '@/constants/colors'
import { ArrowRight, BookOpen, MenuIcon, NotebookText, Users } from 'lucide-react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { OrganizationsCompanyAccountTypeEnum, ThemesQueryQuery } from '@/gql/graphql'
import { routes } from '@/routes/utils'
import useAuthStore from '@/stores/useAuthStore'
import { getThemeLink } from '@/utils/helper'
import { useState } from 'react'
import { cn } from '@/utils/shadcn'
import ToggleMenu from './ToggleMenu'
import useNavStore from '@/stores/useNavStore'
import Request from '@/assets/icons/Request'
import StatisticsIcon from '@/assets/icons/StatisticsIcon'

const SubMenu = ({ themes }: { themes?: ThemesQueryQuery }) => {
  const { accountType } = useAuthStore()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { showManagement } = useNavStore()

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <button className="outline-none">
          <MenuIcon className="m-2 h-6 w-6 xl:hidden" />
        </button>
      </SheetTrigger>
      <SheetContent
        side="left"
        className={cn(
          'mt-14 w-screen !max-w-none border-t border-deep-cove/10 p-7 lg:mt-20 [&>button]:hidden',
        )}
      >
        <SheetFooter className="overflow-auto border-none px-0">
          <SheetClose asChild>
            <div className="grid h-full grid-cols-1 gap-10">
              <div className="h-[calc(100svh-100px)] overflow-auto pr-4">
                <ToggleMenu className="mx-auto mb-6 flex lg:hidden" />
                <div className="flex items-center space-x-4 divide-x-2 text-lg capitalize text-secondary-arapawa xl:hidden"></div>
                <h3 className="font-medium">
                  {showManagement ? t('menu.manage') : t('menu.themes')}
                </h3>
                <div className="mt-8 space-y-6 border-l border-deep-cove/10 pl-8">
                  {showManagement ? (
                    <>
                      <Link to={routes.manageSeats.getPath()} className="flex items-center gap-2.5">
                        <Users size={18} /> {t('label.users')}
                      </Link>
                      <Link
                        to={routes.manageRequests.getPath()}
                        className="flex items-center gap-2.5"
                      >
                        <Request className="h-4 w-4.5" /> {t('label.requests')}
                      </Link>
                      <Link
                        to={routes.companyStatistics.getPath()}
                        className="flex items-center gap-2.5"
                      >
                        <StatisticsIcon className="h-4 w-4.5" /> {t('label.statistics')}
                      </Link>
                    </>
                  ) : (
                    themes?.themes.map((theme) => (
                      <Link
                        to={getThemeLink(theme.slug, theme.id, accountType)}
                        key={theme.id}
                        className="block"
                        onClick={() => setOpen(false)}
                      >
                        <div className="flex items-center gap-4 font-medium">
                          <ThemeLogo
                            color={colors.deepCove}
                            url={theme.icon?.url || ''}
                            className="h-6 w-6"
                          />
                          {theme.name}
                        </div>
                      </Link>
                    ))
                  )}
                </div>
                <h3 className="mt-11 font-medium">{t('label.articles')}</h3>
                <div className="mt-8 space-y-6 border-l border-deep-cove/10 pl-8">
                  <Link
                    to={routes.blogs.getPath()}
                    className="flex items-center gap-4 font-medium"
                    onClick={() => setOpen(false)}
                  >
                    <NotebookText size={18} /> {t('title.blogs')}
                  </Link>
                  <Link
                    to={routes.guides.getPath()}
                    className="flex items-center gap-4 font-medium"
                    onClick={() => setOpen(false)}
                  >
                    <BookOpen size={18} /> {t('title.guides')}
                  </Link>
                </div>
                {showManagement && (
                  <Link
                    to={routes.teamOffer.getPath()}
                    className="mt-11 flex items-center gap-4 font-medium"
                    onClick={() => setOpen(false)}
                  >
                    {t('subtitle.team_offer')} <ArrowRight size={18} />
                  </Link>
                )}
                {accountType !== OrganizationsCompanyAccountTypeEnum.Light && !showManagement && (
                  <Link
                    to={routes.vendors.getPath()}
                    className="mt-11 flex items-center gap-4 font-medium"
                    onClick={() => setOpen(false)}
                  >
                    {t('title.experts')} <ArrowRight size={18} />
                  </Link>
                )}
              </div>
            </div>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

export default SubMenu
