import { FavoritesQuery } from "@/gql/graphql"
import { create } from 'zustand'

type FavoriteItemType = FavoritesQuery['favorites'][0]

export const identifier = (favorite: FavoriteItemType) => `${favorite.itemId}_${favorite.type}`

interface FavoriteStore {
  items: Record<string, FavoriteItemType>
  setItems: (items: FavoriteItemType[]) => void
  addFavorite: (favorite: FavoriteItemType) => void
  removeFavorite: (favorite: FavoriteItemType) => void
}

const useFavoriteStore = create<FavoriteStore>((set, get) => ({
  items: {},
  setItems: (_items: FavoriteItemType[]) => {
    const convertedItems = {} as Record<string, FavoriteItemType>
    _items.forEach((item) => {
      convertedItems[identifier(item)] = item
    })
    set({items: convertedItems})
  },
  addFavorite: (favorite: FavoriteItemType) => set({items: {...get().items, [identifier(favorite)]: favorite}}),
  removeFavorite: (favorite: FavoriteItemType) => {
    const favorites = get().items
    delete favorites[identifier(favorite)]
    set({items: favorites})
  }
}))

export default useFavoriteStore
