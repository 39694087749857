import Platform from '@/assets/icons/Platform'
import { routes } from '@/routes/utils'
import useNavStore from '@/stores/useNavStore'
import { cn } from '@/utils/shadcn'
import { SlidersHorizontal } from 'lucide-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

const ToggleMenu = ({ className }: { className?: string }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { showManagement, setShowManagement } = useNavStore()
  const { t } = useTranslation()

  useEffect(() => {
    if (
      [
        routes.companyDashboard.getPath(),
        routes.manageRequests.getPath(),
        routes.manageSeats.getPath(),
      ].includes(pathname)
    ) {
      setShowManagement(true)
    }
  }, [])

  const handleToggleMenu = (isManaging) => {
    setShowManagement(isManaging)
    if (isManaging) {
      navigate(routes.companyDashboard.getPath())
    } else {
      navigate('/')
    }
  }

  return (
    <div
      className={cn(
        'mr-6 flex h-10 w-fit rounded-full bg-[#F1EFEB] p-1 text-xs font-medium xl:text-sm',
        className,
      )}
    >
      <div
        className={cn('flex cursor-pointer items-center gap-2.5 rounded-full px-4 opacity-50', {
          'bg-[#BFE1F1] opacity-100': showManagement,
        })}
        onClick={() => handleToggleMenu(true)}
      >
        <SlidersHorizontal size={16} />
        {t('menu.manage')}
      </div>
      <div
        className={cn('flex cursor-pointer items-center gap-2.5 rounded-full px-4 opacity-50', {
          'bg-[#F9E4B4] opacity-100': !showManagement,
        })}
        onClick={() => handleToggleMenu(false)}
      >
        <Platform />
        Platform
      </div>
    </div>
  )
}

export default ToggleMenu
