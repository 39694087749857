import { useMutationGraphQL } from '@/use-graphql'
import { createMessageTemplate, updateMessageTemplate } from '../graphql/mutation'
import { useState } from 'react'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Button } from '@/components/ui/button'
import { toast } from 'react-toastify'
import { Loader2 } from 'lucide-react'
import { MessageTemplate } from '../pages/MessageTemplateList'
import { MentionsInput, Mention } from 'react-mentions'
import { predefinedVariables } from "../utils"

const MessageTemplateForm = ({
  data,
  close,
  refetch,
}: {
  data?: MessageTemplate
  close?: () => void
  refetch: () => void
}) => {
  const [name, setName] = useState(data?.name || '')
  const [content, setContent] = useState(data?.content || '')
  const [loading, setLoading] = useState(false)

  const createMutate = useMutationGraphQL(
    createMessageTemplate,
    {
      input: {
        name,
        content,
      },
    },
    {
      onSuccess: () => {
        refetch()
        close?.()
      },
    },
  )
  const updateMutate = useMutationGraphQL(
    updateMessageTemplate,
    {
      id: data?.id || '',
      input: {
        name,
        content,
      },
    },
    {
      onSuccess: () => {
        refetch()
        close?.()
      },
    },
  )

  const handleSubmit = () => {
    setLoading(true)
    if (!name || !content) {
      setLoading(false)
      return toast.warning('Please fill in all fields')
    }
    if (data?.id) {
      updateMutate.mutate()
    } else {
      createMutate.mutate()
    }
  }

  return (
    <div>
      <h1 className="mb-8 text-2xl text-brand-torea">
        {data?.id ? 'Update' : 'Create'} message template
      </h1>
      <Input label="Name" value={name} onChange={(e) => setName(e.target.value)} />
      <Label className="mb-1 mt-6 block text-brand-torea">Content</Label>
      <MentionsInput
        style={{ resize: 'none' }}
        rows={8}
        placeholder="Type # to add pre-defined variables..."
        className="mentions"
        value={content}
        onChange={(_, newValue) => setContent(newValue)}
        markup="@{{__display__}}"
      >
        <Mention
          trigger="#"
          data={predefinedVariables}
          className="mentions__mention"
        />
      </MentionsInput>
      <div className="mt-8 flex w-full justify-end">
        <Button
          className="rounded-md bg-brand-torea px-4 py-2 text-white"
          onClick={handleSubmit}
          disabled={loading}
        >
          Submit {loading && <Loader2 className="animate-spin" />}
        </Button>
      </div>
    </div>
  )
}

export default MessageTemplateForm
