const Platform = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        opacity="0.5"
        d="M14.7266 1.02344C15.3828 1.65234 14.7266 3.42969 13.25 5.58984C13.5234 6.27344 13.6602 7.01172 13.6602 7.75C13.6602 10.8672 11.1445 13.4375 8 13.4375C7.26172 13.4375 6.52344 13.2734 5.83984 13C3.67969 14.4766 1.90234 15.1328 1.24609 14.4766C0.617188 13.8477 1.27344 12.0703 2.75 9.91016C2.47656 9.22656 2.3125 8.48828 2.3125 7.75C2.3125 4.63281 4.85547 2.08984 8 2.08984C8.73828 2.08984 9.47656 2.22656 10.1602 2.5C12.3203 1.02344 14.0977 0.367188 14.7266 1.02344ZM2.75 13C2.96875 13.2188 3.625 12.9453 4.58203 12.3164C4.14453 11.9883 3.76172 11.6055 3.43359 11.168C2.80469 12.125 2.53125 12.7812 2.75 13ZM5.70312 11.4688C6.66016 10.7305 7.75391 9.74609 8.84766 8.65234C9.96875 7.53125 10.9531 6.4375 11.6914 5.48047C10.9258 4.22266 9.55859 3.375 7.97266 3.375C5.56641 3.375 3.59766 5.34375 3.59766 7.75C3.59766 9.33594 4.44531 10.7031 5.70312 11.4688ZM8 12.125C10.4062 12.125 12.375 10.1836 12.375 7.75C12.375 7.47656 12.3203 7.17578 12.2656 6.875C11.5 7.83203 10.6797 8.73438 9.83203 9.60938C8.95703 10.457 8.05469 11.2773 7.09766 12.043C7.39844 12.0977 7.69922 12.125 8 12.125ZM11.3906 3.21094C11.8281 3.53906 12.2109 3.92188 12.5391 4.35938C13.168 3.40234 13.4688 2.74609 13.25 2.52734C13.0312 2.30859 12.3477 2.58203 11.3906 3.21094Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Platform
