const RightArc = ({className}: {className?: string}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15" fill="none" className={className}>
      <path
        d="M12.6875 7.75C12.6875 4.63281 10.1172 2.0625 6.97266 2.0625C6.61719 2.0625 6.31641 1.78906 6.31641 1.40625C6.31641 1.05078 6.61719 0.75 6.97266 0.75C10.8555 0.75 14 3.89453 14 7.75C14 11.6328 10.8555 14.75 6.97266 14.75C6.61719 14.75 6.31641 14.4766 6.31641 14.0938C6.31641 13.7383 6.61719 13.4375 6.97266 13.4375C10.1172 13.4375 12.6875 10.8945 12.6875 7.75ZM9.40625 7.28516C9.54297 7.39453 9.625 7.58594 9.625 7.75C9.625 7.94141 9.54297 8.10547 9.40625 8.24219L6.34375 11.0859C6.07031 11.332 5.66016 11.3047 5.41406 11.0586C5.16797 10.7852 5.16797 10.375 5.44141 10.1289L7.27344 8.40625H0.65625C0.273438 8.40625 0 8.13281 0 7.75C0 7.39453 0.273438 7.09375 0.65625 7.09375H7.27344L5.44141 5.39844C5.16797 5.15234 5.16797 4.74219 5.41406 4.46875C5.66016 4.19531 6.07031 4.19531 6.34375 4.44141L9.40625 7.28516Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default RightArc
