import SelectInput from '@/components/SelectInput'
import useMessageStore from "@/stores/useMessageStore"
import { Search } from 'lucide-react'

const SearchAndFilter = () => {
  const {search, setSearch, filter, setFilter} = useMessageStore()

  return (
    <div className="-mr-3 flex items-end gap-2">
      <div className="relative w-52 rounded-full border bg-white px-4 py-2.5 pr-10 text-sm">
        <input
          className="h-full w-full outline-none placeholder:text-grey-black"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Search className="absolute right-4 top-2.5 text-brand-torea" size={20} />
      </div>
      <SelectInput
        className="w-28"
        label="Filter"
        value={filter}
        onChange={setFilter}
        options={[
          { label: 'None', value: 'none' },
          { label: 'Unread', value: 'markedUnread' },
          { label: 'Flagged', value: 'flagged' },
          { label: 'Archived', value: 'archived' },
        ]}
      />
    </div>
  )
}

export default SearchAndFilter
