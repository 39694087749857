import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import App from './App.tsx'
import './index.scss'
import './i18n'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import env from './constants/env.ts'

const setupErrorTracking = () => {
  if ((env.environment === 'tst' || env.environment === 'production') && env.sentryDns) {
    Sentry.init({
      dsn: env.sentryDns,
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      // Performance Monitoring
      tracesSampleRate: 0.01,
      // Session Replay
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
      environment: env.environment,
    })
  }
}

setupErrorTracking()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
)
