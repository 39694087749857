import { graphql } from '@/gql'

export const sendMessageMutation = graphql(`
  mutation SendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      id
    }
  }
`)

export const readChatMutation = graphql(`
  mutation ReadChat($chatId: ID!) {
    readChat(input: { chatId: $chatId }) {
      id
    }
  }
`)

export const createChatMutation = graphql(`
  mutation CreateChat($input: CreateChatInput!) {
    createChat(input: $input) {
      id
      participant2 {
        name
      }
      participant1 {
        isCurrentUser
      }
    }
  }
`)

export const bookingDateApprovalMutation = graphql(`
  mutation BookingDateApproval($id: Int!) {
    bookingDateApproval(input: { id: $id, approved: true }) {
      ... on BookingType {
        id
      }
    }
  }
`)

export const sendEmailByAdminMutation = graphql(`
  mutation SendEmailForMessageByAdmin($id: ID!) {
    sendEmailForMessageByAdmin(id: $id)
  }
`)

export const sendEmailMutation = graphql(`
  mutation SendEmailForMessage($id: ID!) {
    sendEmailForMessage(id: $id)
  }
`)

export const updateMessage = graphql(`
  mutation UpdateMessage($id: ID!, $input: UpdateMessageInput!) {
    updateMessage(id: $id, input: $input) {
      id
    }
  }
`)

export const updateChat = graphql(`
  mutation UpdateChat($id: ID!, $input: UpdateChatInput!) {
    updateChat(id: $id, input: $input) {
      id
    }
  }
`)
