import { graphql } from '@/gql'

export const sharedInfoDetailQuery = graphql(`
  query ShareUserInfo($slug: String!) {
    sharedUserInfo(slug: $slug) {
      message
      status
      vendors {
        name
      }
      user {
        firstName
        lastName
        email
        companyMember {
          company {
            name
          }
          workEnergy
          privateEnergy
        }
        userThemes {
          ...ScoredThemeFragment
        }
        nonThemeAnswers {
          text
          answer
        }
      }
    }
  }
`)
