import {
  UseMutationOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query'

import { ExecutionResult } from 'graphql'
import { TypedDocumentString } from './gql/graphql'
import env from './constants/env'
import useAuthStore from './stores/useAuthStore'

const fetcher = <TResult, TVariables>(
  document: TypedDocumentString<TResult, TVariables>,
  variables?: TVariables,
  headers?: Record<string, string>,
) => {
  return () =>
    fetch(`${env.graphqlUrl}`, {
      method: 'POST',
      credentials: 'include', // Send cookie to server
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify({
        query: document.toString(),
        variables,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        const { data, errors } = response
        if (errors?.length) {
          return Promise.reject(new Error(errors[0].message))
        }
        return data
      }) as Promise<ExecutionResult<TResult>>
}

export function useQueryGraphQL<TResult, TVariables>(
  queryKeys: unknown[],
  document: TypedDocumentString<TResult, TVariables>,
  variables?: TVariables,
  options?: UseQueryOptions<TResult, any, TResult>,
  headers?: Record<string, string>,
) {
  const { getOrgSlug } = useAuthStore()
  const orgType = localStorage.getItem('org-type')
  return useQuery(
    [...queryKeys, variables] as const,
    // @ts-ignore
    fetcher(document, variables, {
      'thrive-org': getOrgSlug(),
      'org-type': orgType || '',
      ...headers,
    }),
    options,
  )
}

export const getNextPageParam = ({ allPages, totalCount, limit }): number | undefined => {
  if (allPages.length * limit >= totalCount || 0) {
    return undefined
  }
  return allPages.length + 1
}

export function useInfiniteQueryGraphQL<TResult, TVariables>(
  queryKeys: unknown[],
  document: TypedDocumentString<TResult, TVariables>,
  variables?: TVariables,
  options?: UseQueryOptions<TResult, any, TResult>,
  headers?: Record<string, string>,
) {
  const { getOrgSlug } = useAuthStore()
  const orgType = localStorage.getItem('org-type')
  // @ts-ignore
  const limit = variables?.pagination?.limit

  return useInfiniteQuery(
    [...queryKeys, variables] as const,
    // @ts-ignore
    ({ pageParam = 1 }) =>
      // @ts-ignore
      fetcher(
        document,
        // @ts-ignore
        { ...variables, pagination: { limit: limit, offset: limit * (pageParam - 1) } },
        {
          'thrive-org': getOrgSlug(),
          'org-type': orgType || '',
          ...headers,
        },
      )(),
    options,
  )
}

export function useMutationGraphQL<TResult, TVariables>(
  document: TypedDocumentString<TResult, TVariables>,
  variables: TVariables,
  options?: UseMutationOptions,
  headers?: Record<string, string>,
) {
  const { getOrgSlug } = useAuthStore()
  const orgType = localStorage.getItem('org-type')
  return useMutation(
    fetcher(document, variables, {
      'thrive-org': getOrgSlug(),
      'org-type': orgType || '',
      ...headers,
    }),
    options,
  )
}
