import { useState } from 'react'
import { Campaign } from '../pages/CampaignList'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { MentionsInput, Mention } from 'react-mentions'
import { predefinedVariables } from '../utils'
import { useMutationGraphQL, useQueryGraphQL } from '@/use-graphql'
import { createCampaignMutation, updateCampaignMutation } from '../graphql/mutation'
import { toast } from 'react-toastify'
import { Loader2 } from 'lucide-react'
import ComboBox from '@/components/ComboBox'
import { messageTemplatesQuery, segmentsQuery } from '../graphql/query'
import { DatePicker } from '@/components/DatePicker'

const CampaignForm = ({
  data,
  refetch,
  close,
}: {
  data?: Campaign
  refetch: () => void
  close?: () => void
}) => {
  const [name, setName] = useState(data?.name || '')
  const [message, setMessage] = useState(data?.message || '')
  const [segment, setSegment] = useState(data?.segment?.id || '')
  const [scheduledAt, setScheduledAt] = useState(
    data?.scheduledAt ? new Date(data.scheduledAt) : null,
  )
  const [loading, setLoading] = useState(false)
  const { data: segments } = useQueryGraphQL(['segments'], segmentsQuery, {
    pagination: { limit: 99 },
  })
  const { data: templates } = useQueryGraphQL(['message-templates'], messageTemplatesQuery, {
    pagination: { limit: 99 },
  })
  const messageTemplates = templates?.campaignMessageTemplates?.items || []

  const input = {
    name,
    message,
    segment,
    scheduledAt,
  }

  const createMutate = useMutationGraphQL(
    createCampaignMutation,
    {
      input,
    },
    {
      onSuccess: () => {
        refetch()
        close?.()
      },
    },
  )
  const updateMutate = useMutationGraphQL(
    updateCampaignMutation,
    {
      id: data?.id || '',
      input,
    },
    {
      onSuccess: () => {
        refetch()
        close?.()
      },
    },
  )

  const handleSubmit = () => {
    setLoading(true)
    if (!name || !message || !segment) {
      setLoading(false)
      return toast.warning('Please fill in all fields')
    }
    if (data?.id) {
      updateMutate.mutate()
    } else {
      createMutate.mutate()
    }
  }

  return (
    <div className="container pt-4">
      <h1 className="mb-6 text-2xl text-brand-torea">{data?.id ? 'Update' : 'Create'} campaign</h1>
      <Input label="Name" value={name} onChange={(e) => setName(e.target.value)} />
      <Label className="mb-1 mt-6 block text-brand-torea">Segment</Label>
      <ComboBox
        options={(segments?.segments?.items || []).map((e) => ({
          value: e.id,
          label: `${e.name} (${e.contacts.totalItemsCount} contacts)`,
        }))}
        itemText="segment"
        onChangeValue={(v) => setSegment(v)}
        defaultValue={segment}
      />
      <Label className="mb-1 mt-6 block text-brand-torea">Message</Label>
      <MentionsInput
        style={{ resize: 'none' }}
        rows={8}
        placeholder="Type # to add pre-defined variables..."
        className="mentions"
        value={message}
        onChange={(_, newValue) => setMessage(newValue)}
        markup="@{{__display__}}"
      >
        <Mention trigger="#" data={predefinedVariables} className="mentions__mention" />
      </MentionsInput>
      <Label className="mb-1 mt-3 block text-gray-500">Or use message from template</Label>
      <ComboBox
        options={messageTemplates.map((e) => ({
          value: e.id,
          label: e.name,
        }))}
        itemText="template"
        defaultValue=""
        onChangeValue={(v) => setMessage(messageTemplates.find((e) => e.id === v)?.content || '')}
      />

      <DatePicker
        value={scheduledAt}
        onChange={setScheduledAt}
        label="Scheduled at (Send now if empty)"
        className="mt-8 w-full"
        format="dd MMM yyyy"
        showClearButton
      />

      <div className="mt-8 flex w-full justify-end">
        <Button
          className="rounded-md bg-brand-torea px-4 py-2 text-white"
          onClick={handleSubmit}
          disabled={loading}
        >
          Submit {loading && <Loader2 className="animate-spin" />}
        </Button>
      </div>
    </div>
  )
}

export default CampaignForm
