import useAuthStore from '@/stores/useAuthStore'
import { useMutationGraphQL } from '@/use-graphql'
import { useEffect } from 'react'
import { logoutMutation } from './graphql/mutation'
import { useNavigate } from 'react-router-dom'
import { routes } from '@/routes/utils'

const Logout = () => {
  const navigate = useNavigate()
  const { logout } = useAuthStore()
  const logoutMutate = useMutationGraphQL(
    logoutMutation,
    {},
    {
      onSuccess: () => {
        navigate(routes.requestCode.getPath())
      },
    },
  )

  useEffect(() => {
    localStorage.setItem('org-type', '')
    logout()
    logoutMutate.mutate()
  }, [])

  return null
}

export default Logout
