import paginate from '@/utils/pagination'
import { cn } from '@/utils/shadcn'
import { ArrowLeft, ArrowRight } from 'lucide-react'
import { Button } from './ui/button'

export interface Props {
  totalPages: number
  currentPage: number
  onPageChange: (page: number) => void
  maxPages?: number
}

const Pagination = ({ totalPages, currentPage, onPageChange, maxPages = 10 }: Props) => {
  const { pages, startPage, endPage } = paginate(totalPages, currentPage, maxPages)

  return (
    <div className="flex items-center justify-end">
      <div className="flex gap-8">
        {startPage !== 1 && (
          <button onClick={() => onPageChange(1)} className={cn('text-xl text-brand-torea-medium')}>
            1
          </button>
        )}
        {startPage > 2 && <p>..</p>}
        {pages.map((page) => (
          <button
            onClick={() => onPageChange(page)}
            className={cn('text-xl text-brand-torea-medium', {
              'text-brand-torea': page === currentPage,
            })}
            key={page}
          >
            {page}
          </button>
        ))}
        {endPage < totalPages && <p>..</p>}
        {endPage !== totalPages && (
          <button onClick={() => onPageChange(1)} className={cn('text-xl text-brand-torea-medium')}>
            {totalPages}
          </button>
        )}
      </div>
      <div className="ml-10 flex gap-2">
        <Button
          variant="ghost"
          size="icon"
          className="text-secondary-casper"
          onClick={() => currentPage !== 1 && onPageChange(currentPage - 1)}
        >
          <ArrowLeft />
        </Button>
        <Button
          variant="secondary"
          size="icon"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <ArrowRight />
        </Button>
      </div>
    </div>
  )
}

export default Pagination
