import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import useDebounceValue from '@/hooks/useDebounceValue'
import useModalStore from '@/stores/useModalStore'
import { useQueryGraphQL } from '@/use-graphql'
import { UserPlus } from 'lucide-react'
import { useState } from 'react'
import { campaignUsersQuery } from '../graphql/query'
import DataTable from '@/components/data-table'
import { columns } from '../pages/SegmentDetail'
import { toast } from 'react-toastify'

const AddNewContacts = ({ onAddContacts }: { onAddContacts: (v: any[]) => void }) => {
  const { addModal } = useModalStore()

  const showModal = () => {
    addModal({
      name: 'add-contact',
      maxWidth: 1200,
      Component: ({ close }: { close?: () => void }) => {
        const [search, setSearch] = useState('')
        const debouncedSearch = useDebounceValue(search, 300)
        const [selectedRows, setSelectedRows] = useState<any[]>([])
        const { data } = useQueryGraphQL(['search-users'], campaignUsersQuery, {
          filters: {
            email: { iContains: debouncedSearch },
            OR: {
              firstName: { iContains: debouncedSearch },
              OR: {
                lastName: { iContains: debouncedSearch },
              },
            },
          },
          pagination: { limit: 99 },
        })

        const handleAddContacts = () => {
          onAddContacts(selectedRows)
          toast.success('Success')
          close?.()
        }

        return (
          <div>
            <h2 className="text-xl text-brand-torea">Add new contact</h2>
            <Input
              className="mt-8"
              placeholder="Search by name or email..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            <div className="mt-8 flex justify-end">
              <Button variant="outline" onClick={() => close?.()}>
                Cancel
              </Button>
              <Button
                className="ml-4"
                onClick={handleAddContacts}
                disabled={selectedRows.length === 0}
              >
                Add
              </Button>
            </div>

            <DataTable
              data={data?.campaignUsers.items || []}
              columns={columns}
              enableSelection
              onRowSelectionChange={setSelectedRows}
              totalCount={data?.campaignUsers.totalItemsCount || 0}
            />
          </div>
        )
      },
    })
  }

  return (
    <Button size="sm" variant="outline" onClick={showModal}>
      <UserPlus className="text-brand-torea" /> Add new contact
    </Button>
  )
}

export default AddNewContacts
