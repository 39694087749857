const StatisticsIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      className={className}
    >
      <path
        d="M10.2695 5.68359C9.96875 5.95703 9.53125 5.95703 9.20312 5.71094L6.25 3.49609L2.39453 6.55859C2.03906 6.85938 1.49219 6.80469 1.19141 6.42188C0.863281 6.06641 0.945312 5.51953 1.32812 5.19141L5.70312 1.71875C6.00391 1.44531 6.44141 1.44531 6.76953 1.69141L9.72266 3.90625L13.5781 0.84375C13.9336 0.515625 14.4805 0.597656 14.7812 0.980469C15.1094 1.33594 15.0273 1.88281 14.6445 2.18359L10.2695 5.68359ZM5.375 6.75C5.375 6.28516 5.75781 5.875 6.25 5.875C6.71484 5.875 7.125 6.28516 7.125 6.75V12C7.125 12.4922 6.71484 12.875 6.25 12.875C5.75781 12.875 5.375 12.4922 5.375 12V6.75ZM1.875 9.375C1.875 8.91016 2.25781 8.5 2.75 8.5C3.21484 8.5 3.625 8.91016 3.625 9.375V12C3.625 12.4922 3.21484 12.875 2.75 12.875C2.25781 12.875 1.875 12.4922 1.875 12V9.375ZM10.625 8.5V12C10.625 12.4922 10.2148 12.875 9.75 12.875C9.25781 12.875 8.875 12.4922 8.875 12V8.5C8.875 8.03516 9.25781 7.625 9.75 7.625C10.2148 7.625 10.625 8.03516 10.625 8.5ZM12.375 6.75C12.375 6.28516 12.7578 5.875 13.25 5.875C13.7148 5.875 14.125 6.28516 14.125 6.75V12C14.125 12.4922 13.7148 12.875 13.25 12.875C12.7578 12.875 12.375 12.4922 12.375 12V6.75Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default StatisticsIcon
