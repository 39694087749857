import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationEN from '../src/locales/EN-GB.json'
import translationNL from '../src/locales/NL-NL.json'

const resources = {
  en: {
    translation: translationEN,
  },
  nl: {
    translation: translationNL,
  },
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: localStorage.getItem('language') || 'nl',
    fallbackLng: 'nl',
    resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
