import { Link } from 'react-router-dom'
import Unauthorized from '@/assets/images/Unauthorized.png'
import { routes } from '@/routes/utils'

const UnauthorizedPage = () => {
  return (
    <div className="flex h-[80vh] w-screen items-center justify-center">
      <Link to={routes.logout.getPath()}>
        <img src={Unauthorized} />
      </Link>
    </div>
  )
}

export default UnauthorizedPage
