import ReactOtpInput from 'react-otp-input'
import { Label } from './ui/label'
import { cn } from '@/utils/shadcn'

export interface Props {
  label: string
  value: string | undefined
  onChange: (otp: string) => void
  onFinish: (otp: string) => void
  inputType?: 'number' | 'password' | 'tel' | 'text'
  numInputs?: number
  error?: string
}

const OtpInput = ({
  label,
  value,
  onChange,
  onFinish,
  numInputs = 6,
  inputType = 'number',
  error,
}: Props) => {
  const handleChange = (otp: string) => {
    onChange(otp)
    if (!error && otp.length === numInputs) {
      // Automatically handle a logic when all characters are entered
      onFinish(otp)
    }
  }

  return (
    <div className="w-fit">
      <div className="flex items-center justify-between">
        <Label>{label}</Label>
        <Label
          className={cn('text-brand-valencia opacity-0 transition duration-300 ease-in-out', {
            'opacity-1': !!error,
          })}
        >
          {error}
        </Label>
      </div>
      <ReactOtpInput
        value={value}
        onChange={handleChange}
        numInputs={numInputs}
        shouldAutoFocus
        inputType={inputType}
        renderInput={(props) => (
          <input
            {...props}
            placeholder={' '}
            style={{}}
            className={cn(
              'mr-2.5 h-14 w-12 rounded border border-grey-medium px-3 py-3 text-center text-[28px] text-brand-torea transition duration-300 last:mr-0 focus:border-brand-torea focus:outline-none [&:not(:placeholder-shown)]:border-brand-torea',
              { '!border-brand-valencia': !!error },
            )}
            inputMode="numeric"
            autoComplete="one-time-code"
          />
        )}
      />
    </div>
  )
}

export default OtpInput
