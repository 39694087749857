import { graphql } from '@/gql'

export const updateLanguageMutation = graphql(`
  mutation UpdateLanguage($language: UsersUserLanguageEnum!) {
    updateLanguage(input: { language: $language }) {
      ... on UserType {
        language
      }
    }
  }
`)
