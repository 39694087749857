import { create } from 'zustand'

const useSmileyStore = create<{ openPopup: boolean; setOpenPopup: (value: boolean) => void }>(
  (set) => ({
    openPopup: false,
    setOpenPopup: (openPopup: boolean) => set({ openPopup }),
  }),
)

export default useSmileyStore
