import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Check } from 'lucide-react'

import { cn } from '@/utils/shadcn'

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
  // @ts-ignore
>(({ className, dark, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'peer h-[18px] w-[18px] shrink-0 outline-none rounded-sm border border-deep-cove disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-deep-cove data-[state=checked]:text-primary-foreground',
      {
        'border-gray-500 data-[state=checked]:border-brand-cream data-[state=checked]:bg-brand-cream data-[state=checked]:text-popup-background':
          dark,
      },
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
      <Check className="h-4 w-4" strokeWidth={3} />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
