import QueryBuilder, { defaultValidator, RuleGroupType } from 'react-querybuilder'
import { convertQuery, flattenQuery } from '../utils'
import { useMutationGraphQL, useQueryGraphQL } from '@/use-graphql'
import { campaignUsersQuery, segmentDetailQuery } from '../graphql/query'
import { useEffect, useState } from 'react'
import { fields } from '../fields'
import { Button } from '@/components/ui/button'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowLeft, ListRestart, Loader2, Pencil, Trash2, X } from 'lucide-react'
import { Input } from '@/components/ui/input'
import { toast } from 'react-toastify'
import { createSegmentMutation, updateSegmentMutation } from '../graphql/mutation'
import { isEqual, omit, unionBy } from 'lodash'
import { routes } from '@/routes/utils'
import DataTable from '@/components/data-table'
import { ColumnDef } from '@tanstack/react-table'
import { CampaignUsersQuery } from '@/gql/graphql'
import { formatDate } from '@/utils/datetime'
import useModalStore from '@/stores/useModalStore'
import AddNewContacts from '../components/AddNewContact'
import { CustomValueEditor } from '../components/CustomValueEditor'
import { transformErrorMessage } from '@/utils/handle-string'

const initialQuery: RuleGroupType = { combinator: 'and', rules: [] }

export const columns: ColumnDef<CampaignUsersQuery['campaignUsers']['items'][0]>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row }) => (
      <div>
        {row.original.firstName} {row.original.lastName}
      </div>
    ),
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'phoneNumber',
    header: 'Phone number',
  },
  {
    accessorKey: 'companyMember.company.name',
    header: 'Company',
  },
  {
    accessorKey: 'companyMember.budget',
    header: 'Budget',
  },
  {
    accessorKey: 'lastLogin',
    header: 'Last login',
    cell: ({ row }) => (
      <div>
        {row.getValue('lastLogin')
          ? formatDate(new Date(row.getValue('lastLogin')), 'dd MMM yyyy HH:mm')
          : 'None'}
      </div>
    ),
  },
]

const SegmentDetail = () => {
  const navigate = useNavigate()
  const { id = '' } = useParams()
  const [query, setQuery] = useState(initialQuery)
  const [name, setName] = useState('')
  const [editingName, setEditingName] = useState(false)
  const [contacts, setContacts] = useState<any[]>([])
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const { addModal } = useModalStore()
  const [version, setVersion] = useState(0)
  console.log('ConvertQuery::', convertQuery(query))
  console.log('Query::', flattenQuery(convertQuery(query)))

  const { data } = useQueryGraphQL(
    ['segment-detail'],
    segmentDetailQuery,
    { id },
    { enabled: id !== 'create' },
  )
  const { refetch, isFetching } = useQueryGraphQL(
    ['campaign-users'],
    campaignUsersQuery,
    { filters: flattenQuery(convertQuery(query)), pagination: { limit: 9999 } },
    {
      enabled: false,
      keepPreviousData: true,
      onSuccess: (data: any) => setContacts(data.campaignUsers.items),
      onError: (error: any) => toast.error(transformErrorMessage(error)),
    },
  )

  const dataInput = {
    name,
    filters: JSON.stringify(query),
    contacts: contacts.map((e) => e.id) || [],
  }

  const createSegmentMutate = useMutationGraphQL(
    createSegmentMutation,
    {
      input: dataInput,
    },
    {
      onSuccess: (data: any) => {
        toast.success('Segment created')
        navigate(routes.segmentDetail.getPath(data.createSegment.id))
      },
    },
  )
  const updateSegmentMutate = useMutationGraphQL(
    updateSegmentMutation,
    {
      id,
      input: dataInput,
    },
    {
      onSuccess: () => {
        toast.success('Segment updated')
        setEditingName(false)
      },
    },
  )

  useEffect(() => {
    if (data?.segmentDetail?.filters) {
      // @ts-ignore
      setQuery(JSON.parse(data.segmentDetail.filters))
      // @ts-ignore
      setContacts(data.segmentDetail.contacts.items)
    }
    if (data?.segmentDetail?.name) {
      setName(data.segmentDetail.name)
    }
  }, [data])

  const synchronizeContacts = () => {
    if (isEqual(omit(query, 'id'), initialQuery)) {
      toast.error('Please add some filters to the segment')
      return
    }
    refetch()
  }

  const handleSubmit = () => {
    if (!name) {
      toast.error('Please enter a name for the segment')
      return
    }
    if (id === 'create') {
      createSegmentMutate.mutate()
    } else {
      updateSegmentMutate.mutate()
    }
  }

  const showConfirmModal = () => {
    addModal({
      name: 'remove-selected-contacts',
      Component: ({ close }) => {
        return (
          <div className="p-10">
            <h3>Do you want to remove the selected contacts?</h3>
            <div className="mt-10 flex justify-end gap-4">
              <Button
                onClick={() => {
                  setContacts((c) =>
                    c.filter((contact) => !selectedRows.map((r) => r.id).includes(contact.id)),
                  )
                  setVersion((v) => v + 1)
                  close?.()
                }}
              >
                Yes
              </Button>
              <Button variant="outline" onClick={close}>
                No
              </Button>
            </div>
          </div>
        )
      },
      isCardStyle: true,
      maxWidth: 700,
    })
  }

  return (
    <div className="container pt-4">
      <Button size="sm" onClick={() => navigate(routes.campaignDashboard.getPath())}>
        <ArrowLeft size={18} /> Back
      </Button>
      <h1 className="mb-6 text-center text-2xl font-semibold text-secondary-arapawa">
        {id === 'create' ? 'Create' : 'Update'} Segment
      </h1>
      <div className="my-4 flex items-center gap-3">
        {editingName || id === 'create' ? (
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-80 [&>.labels]:hidden"
            placeholder="Enter segment name"
          />
        ) : (
          <h1 className="text-xl font-semibold text-brand-torea">{name}</h1>
        )}
        {id !== 'create' &&
          (editingName ? (
            <X
              className="cursor-pointer text-brand-valencia"
              onClick={() => setEditingName(false)}
            />
          ) : (
            <Pencil
              className="cursor-pointer text-brand-valencia"
              size={20}
              onClick={() => setEditingName(true)}
            />
          ))}
      </div>
      <QueryBuilder
        fields={fields}
        query={query}
        onQueryChange={setQuery}
        validator={defaultValidator}
        controlElements={{ valueEditor: CustomValueEditor }}
      />
      <div className="my-4 flex gap-4">
        <Button onClick={synchronizeContacts} variant="secondary" disabled={isFetching}>
          {isFetching ? <Loader2 className="animate-spin text-white" /> : <ListRestart />}
          Sync contacts
        </Button>
        <Button onClick={handleSubmit}>Save segment</Button>
      </div>
      {(contacts.length || 0) === 0 ? (
        <p>No contacts found</p>
      ) : (
        <div className="relative">
          <AddNewContacts
            onAddContacts={(_contacts) => setContacts(unionBy([..._contacts, ...contacts], 'id'))}
          />

          {selectedRows.length > 0 && (
            <Button
              size="sm"
              variant="outline"
              className="ml-2 sm:absolute sm:left-80 sm:top-14"
              onClick={showConfirmModal}
            >
              <Trash2 className="text-brand-valencia" />
            </Button>
          )}

          <DataTable
            data={contacts || []}
            columns={columns}
            enableSelection
            onRowSelectionChange={setSelectedRows}
            key={version}
            showColumnFilter
            showEmailFilter
            totalCount={contacts?.length || 0}
          />
        </div>
      )}
    </div>
  )
}

export default SegmentDetail
