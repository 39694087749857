import { graphql } from '@/gql'

export const createSegmentMutation = graphql(`
  mutation CreateSegment($input: SegmentInput!) {
    createSegment(input: $input) {
      id
    }
  }
`)

export const updateSegmentMutation = graphql(`
  mutation UpdateSegment($id: ID!, $input: SegmentInput!) {
    updateSegment(id: $id, input: $input) {
      id
    }
  }
`)

export const createMessageTemplate = graphql(`
  mutation CreateCampaignMessageTemplate($input: CampaignMessageTemplateInput!) {
    createCampaignMessageTemplate(input: $input) {
      content
      id
      name
    }
  }
`)

export const updateMessageTemplate = graphql(`
  mutation UpdateCampaignMessageTemplate($id: ID!, $input: CampaignMessageTemplateInput!) {
    updateCampaignMessageTemplate(id: $id, input: $input) {
      content
      id
      name
    }
  }
`)

export const createCampaignMutation = graphql(`
  mutation CreateCampaign($input: CampaignInput!) {
    createCampaign(input: $input) {
      message
      name
      id
      sentAt
      segment {
        name
        filters
        contacts {
          totalItemsCount
        }
      }
      scheduledAt
    }
  }
`)

export const updateCampaignMutation = graphql(`
  mutation UpdateCampaign($id: ID!, $input: CampaignInput!) {
    updateCampaign(id: $id, input: $input) {
      message
      name
      id
      sentAt
      segment {
        name
        filters
        contacts {
          totalItemsCount
        }
      }
      scheduledAt
    }
  }
`)

export const deleteCampaignMutation = graphql(`
  mutation DeleteCampaign($id: ID!) {
    deleteCampaign(id: $id)
  }
`)
