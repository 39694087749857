import { OrganizationsCompanyAccountTypeEnum } from "@/gql/graphql"
import { routes } from "@/routes/utils"

export const sleep = (ms = 1000) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

// relevance = 1 - score
export const convertRelevance = (relevance: number) => {
  if (relevance < 0.5) return 3
  if (relevance >= 0.5 && relevance < 0.75) return 4
  return 5
}

export const getThemeLink = (slug: string, id?: string, accountType?: OrganizationsCompanyAccountTypeEnum) => {
  if (accountType === OrganizationsCompanyAccountTypeEnum.Light) {
    return `${routes.searchPage.getPath()}?theme=${id}`
  }

  return routes.theme.getPath(slug)
}