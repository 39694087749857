import { SearchQueryQuery, SearchSearchItemCategoryEnum } from '@/gql/graphql'
import { routes } from '@/routes/utils'
import useAuthStore from '@/stores/useAuthStore'
import { getInfoFromSearchTextResult, parseFloatLocale } from '@/utils/handle-string'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const ProductSearchResult = ({ item }: { item: SearchQueryQuery['search']['items'][0] }) => {
  const { user } = useAuthStore()
  const { t } = useTranslation()
  const { slug, title } = getInfoFromSearchTextResult(
    user?.language == 'en' ? item.textEn : item.text,
  )
  return (
    <Link
      to={
        item.category === SearchSearchItemCategoryEnum.Product
          ? routes.productDetail.getPath(slug)
          : routes.serviceDetail.getPath(slug)
      }
      className="flex w-full flex-col items-center justify-between gap-5 overflow-hidden rounded-lg bg-grey-medium/75 px-5 py-4 md:flex-row"
    >
      <div className="flex min-w-0 flex-col items-center gap-4 md:flex-row">
        <img
          src={item.image?.url}
          alt=""
          className="h-10 w-10 shrink-0 rounded-md object-cover object-center"
        />
        <div className="min-w-0">
          <p
            className={`text-center text-sm font-medium md:text-left ${
              item.isBook ? 'line-clamp-2 md:line-clamp-1' : 'line-clamp-2'
            }`}
          >
            {title}
          </p>
          {item.isBook && (
            <p className="text-center text-xs text-deep-cove/50 md:text-left">
              {item.vendor?.name}
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-col items-end">
        {item.priceAlterText ? (
          <span className="font-medium">{item.priceAlterText}</span>
        ) : (
          <span className="text-base font-semibold md:text-xl">{`€${parseFloatLocale(
            item.price,
          )}`}</span>
        )}
        <p className="text-xs text-deep-cove/50">
          {item.isBook ? t('label.book') : t(`label.${item.category.toLowerCase()}`)}
        </p>
      </div>
    </Link>
  )
}

export default ProductSearchResult
