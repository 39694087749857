import {
  BookMarked,
  BookOpen,
  BotMessageSquare,
  ChevronDown,
  Contact2,
  FolderKanban,
  Home,
  Lock,
  LogOut,
  MessageSquare,
  NotebookText,
} from 'lucide-react'
import { Link } from 'react-router-dom'

import Logo from '/icon-v2.png'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import Avatar from '../Avatar'
import { cn } from '@/utils/shadcn'
import LanguageSelect from '../LanguageSelect'
import { useTranslation } from 'react-i18next'
import useAuthStore from '@/stores/useAuthStore'
import { useQueryGraphQL } from '@/use-graphql'
import { themesQuery } from './graphql/query'
import { UserCircle } from 'lucide-react'
import { parseFloatLocale } from '@/utils/handle-string'
import SubMenu from './SubMenu'
import useMessageStore from '@/stores/useMessageStore'
import { defaultUrl } from '@/routes/RequiredAuth'
import { routes } from '@/routes/utils'
import SearchInput from '@/pages/search/components/SearchInput'
import { OrganizationsCompanyAccountTypeEnum } from '@/gql/graphql'
import ThemesDropdown from './ThemesDropdown'
import ToggleMenu from './ToggleMenu'
import CompanyManageDropdown from './CompanyManageDropdown'
import useNavStore from '@/stores/useNavStore'

const Navbar = ({ className, hasUnreadChat }: { hasUnreadChat: boolean; className?: string }) => {
  const { t } = useTranslation()
  const { user, isStaff, accountType } = useAuthStore()
  const lockBooking = !user?.userThemes.length && !!parseFloat(user?.companyMember?.budget)
  const isCompanyEmployee = !!user?.companyMember
  const isCompanyAdmin = user?.companyMember?.isAdmin || user?.companyMember?.isOwner
  const { activeChat } = useMessageStore()
  const { showManagement } = useNavStore()
  const { data: themes } = useQueryGraphQL(
    ['themes'],
    themesQuery,
    {},
    { enabled: isCompanyEmployee, staleTime: 30 * 60 * 1000, cacheTime: 60 * 60 * 1000 },
  )
  const companyMember = user?.companyMember
  const startWithZeroBudget =
    Number(companyMember?.budget) == 0 && Number(companyMember?.company?.budgetPerEmployee) == 0

  return (
    <nav
      className={cn(
        'fixed left-0 top-0 z-50 flex w-screen items-center justify-between border-b border-deep-cove/5 bg-white p-2 pr-5 text-sm font-medium transition-all duration-300 lg:py-4 xl:px-8',
        className,
      )}
    >
      <div className="hidden items-center md:flex">
        {!isCompanyEmployee && (
          <>
            <img src={Logo} alt="logo" className="mr-4 h-8 w-8 lg:mr-8 lg:h-10 lg:w-10" />

            <Link to={defaultUrl(user?.roles || [], showManagement)}>Dashboard</Link>
          </>
        )}
        {isCompanyEmployee && (
          <>
            <div className="-mb-2 xl:hidden">
              <SubMenu themes={themes} />
            </div>
            <img src={Logo} alt="logo" className="mr-4 h-8 w-8 lg:mr-8 lg:h-10 lg:w-10" />
            <div className="hidden items-center gap-4 pr-5 xl:flex">
              <Link to={defaultUrl(user?.roles || [], showManagement)}>Dashboard</Link>

              {showManagement ? <CompanyManageDropdown /> : <ThemesDropdown themes={themes} />}

              {showManagement && (
                <Link to={routes.teamOffer.getPath()}>{t('team_offer.title')}</Link>
              )}

              <DropdownMenu key="articles">
                <DropdownMenuTrigger asChild>
                  <button className="flex items-center gap-2 outline-none">
                    {t('label.articles')} <ChevronDown className="text-grey-black" size={18} />
                  </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="start"
                  sideOffset={10}
                  className="w-56 p-3 text-deep-cove"
                >
                  <Link to={routes.blogs.getPath()}>
                    <DropdownMenuItem className="flex items-center gap-2 py-4">
                      <NotebookText size={18} /> {t('title.blogs')}
                    </DropdownMenuItem>
                  </Link>
                  <Link to={routes.guides.getPath()}>
                    <DropdownMenuItem className="flex items-center gap-2 py-4">
                      <BookOpen size={18} /> {t('title.guides')}
                    </DropdownMenuItem>
                  </Link>
                </DropdownMenuContent>
              </DropdownMenu>

              {accountType !== OrganizationsCompanyAccountTypeEnum.Light && !showManagement && (
                <Link
                  to={routes.vendors.getPath()}
                  className="rounded-lg p-2 transition hover:text-brand-valencia"
                >
                  {t('title.experts')}
                </Link>
              )}
            </div>
          </>
        )}
      </div>

      <div className="flex items-center gap-4 md:hidden">
        <SubMenu themes={themes} />
        <Link to={defaultUrl(user?.roles || [], showManagement)}>
          <Home className="h-6 w-6" />
        </Link>
      </div>

      <img src={Logo} alt="logo" className="block h-8  w-8 md:hidden" />

      <div className="flex items-center justify-end">
        {isCompanyAdmin && <ToggleMenu className="hidden lg:flex" />}
        <SearchInput className="mr-6 w-52 sm:w-[448px]" themes={themes?.themes || []} />

        <DropdownMenu key="profile-menu">
          <DropdownMenuTrigger asChild>
            <button className="flex items-center outline-none">
              <Avatar
                className="h-8 w-8 lg:h-10 lg:w-10 [&>div]:md:right-0"
                hasNotification={!activeChat && hasUnreadChat}
                name={`${user?.firstName} ${user?.lastName}`}
              />
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" sideOffset={10} className="w-56 p-3 text-grey-black">
            {isCompanyEmployee &&
              !showManagement &&
              accountType !== OrganizationsCompanyAccountTypeEnum.Light && (
                <div className="flex items-center gap-4 px-3 lg:hidden">
                  {lockBooking && (
                    <Lock className="-mt-0.5 text-brand-valencia" size={16} strokeWidth={3} />
                  )}
                  <div className="text-xs font-bold text-secondary-casper">{t('menu.budget')}</div>{' '}
                  €{companyMember?.budget || 0}
                </div>
              )}
            <DropdownMenuSeparator />
            {isCompanyEmployee && (
              <Link to={routes.favorites.getPath()}>
                <DropdownMenuItem className="gap-2">
                  <BookMarked />
                  {t('menu.favorite')}
                </DropdownMenuItem>
              </Link>
            )}
            <DropdownMenuSeparator />
            <Link
              to={
                user?.vendorMember
                  ? routes.vendorDetail.getPath(user.vendorMember.vendor.slug)
                  : routes.profile.getPath()
              }
            >
              <DropdownMenuItem className="gap-2">
                <UserCircle />
                {t('label.profile')}
              </DropdownMenuItem>
            </Link>
            <Link to={routes.messages.getPath()}>
              <DropdownMenuItem className="relative gap-2">
                <MessageSquare />
                {t('label.message')}
                {!activeChat && hasUnreadChat && (
                  <div className="absolute left-7 top-1 z-10 h-2 w-2 rounded-full bg-brand-valencia" />
                )}
              </DropdownMenuItem>
            </Link>

            {isStaff() && (
              <Link to={routes.customerDashboard.getPath()}>
                <DropdownMenuItem className="gap-2">
                  <Contact2 />
                  Admin dashboard
                </DropdownMenuItem>
              </Link>
            )}
            {isStaff() && (
              <Link to={routes.campaignDashboard.getPath()}>
                <DropdownMenuItem className="gap-2">
                  <BotMessageSquare />
                  Campaign dashboard
                </DropdownMenuItem>
              </Link>
            )}
            {user?.isCustomerSupport && (
              <Link to={routes.customerDashboard.getPath()}>
                <DropdownMenuItem className="gap-2">
                  <Contact2 />
                  Customer support
                </DropdownMenuItem>
              </Link>
            )}
            {user?.companyMember?.isAdmin && (
              <Link to={routes.companyDashboard.getPath()}>
                <DropdownMenuItem className="relative gap-2">
                  <FolderKanban />
                  {t('menu.manage')}
                </DropdownMenuItem>
              </Link>
            )}
            {user?.vendorMember && (
              <Link to={routes.vendorDashboard.getPath()}>
                <DropdownMenuItem className="relative gap-2">
                  <FolderKanban />
                  {t('menu.manage')}
                </DropdownMenuItem>
              </Link>
            )}
            <DropdownMenuSeparator />
            <div className="my-2 pl-3" onClick={(e) => e.stopPropagation()}>
              <LanguageSelect />
            </div>
            <DropdownMenuSeparator />
            <Link to={routes.logout.getPath()}>
              <DropdownMenuItem className="gap-2">
                <LogOut />
                Logout
              </DropdownMenuItem>
            </Link>
          </DropdownMenuContent>
        </DropdownMenu>

        {isCompanyEmployee &&
          !showManagement &&
          accountType !== OrganizationsCompanyAccountTypeEnum.Light &&
          !startWithZeroBudget && (
            <div className="relative ml-6 hidden min-w-[88px] select-none rounded-lg border border-deep-cove/10 p-3  lg:block">
              <div className="flex items-center text-sm">
                {lockBooking && (
                  <Lock className="mr-1 text-brand-valencia" size={16} strokeWidth={3} />
                )}{' '}
                €{parseFloatLocale(companyMember?.budget || 0)}
              </div>
              <div className="absolute -top-2.5 left-3 bg-white px-1 text-[8px] font-medium text-deep-cove/50">
                {t('menu.budget')}
              </div>
            </div>
          )}
      </div>
    </nav>
  )
}

export default Navbar
