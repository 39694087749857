import { formatDateTimeForMessage } from '@/utils/datetime'
import { cn } from '@/utils/shadcn'
import AcceptChangeDate from './components/AcceptChangeDate'
import { Flag, MailCheck, User } from 'lucide-react'
import SendEmailButton from './components/SendEmailButton'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card'
import ArchiveMessageButton from './components/ArchiveMessageButton'
import { MessagesQuery } from '@/gql/graphql'
import FlagMessageButton from './components/FlagMessageButton'
import { Tooltip } from '@/components/ToolTip'
import EditMessageButton from './components/EditMessageButton'
import MarkUnreadButton from './components/MarkUnreadButton'

const MessageItem = ({
  item,
  isStaff,
}: {
  item: MessagesQuery['chat']['messages']['items'][0]
  isStaff: boolean
}) => {
  const isSender = item?.direction === 'MessageDirection.OUT'

  if (!item) return null

  return (
    <div className={cn('relative max-w-[80%] xl:max-w-3xl self-start', { 'self-end': isSender })}>
      {isStaff ? (
        <HoverCard openDelay={100} closeDelay={100} key={item.id}>
          <HoverCardTrigger>
            <button
              className={cn(
                'min-w-[100px] whitespace-pre-line rounded bg-secondary-aqua p-2.5 pr-6 text-sm text-left',
                {
                  'bg-brand-torea pr-9 text-white': isSender,
                },
              )}
            >
              {item?.content}
            </button>
          </HoverCardTrigger>
          <HoverCardContent
            side="top"
            align={isSender ? 'end' : 'start'}
            sideOffset={-8}
            className="w-fit bg-white/75 backdrop-blur-sm"
          >
            <div className="flex cursor-pointer items-center gap-4 text-brand-torea">
              {isSender && !item?.sentEmailAt && !item?.readAt && (
                <EditMessageButton id={item?.id} content={item?.content} />
              )}
              <ArchiveMessageButton id={item?.id} archived={item?.archived} />
              {!isSender && <MarkUnreadButton id={item?.id} markedUnread={item?.markedUnread} />}
              <FlagMessageButton id={item?.id} flagged={item?.flagged} />
              {isSender && (
                <Tooltip content={`Sent by: ${item.senderEmail}`}>
                  <User />
                </Tooltip>
              )}
            </div>
          </HoverCardContent>
        </HoverCard>
      ) : (
        <div
          className={cn(
            'min-w-[100px whitespace-pre-line rounded bg-secondary-aqua p-2.5 pr-6 text-sm',
            {
              'bg-brand-torea pr-9 text-white': isSender,
            },
          )}
        >
          {item?.content}
          {!isSender && item?.type?.startsWith('suggest-date-change') && (
            <AcceptChangeDate type={item?.type} />
          )}
        </div>
      )}

      <p className={cn('mt-1 text-xs text-grey-dark xl:text-right', { 'text-right': isSender })}>
        {item?.createdAt && formatDateTimeForMessage(new Date(item?.createdAt))}
      </p>
      {isSender &&
        isStaff &&
        +item?.id > 0 &&
        (item?.sentEmailAt ? (
          <div className="absolute right-1 top-2 rounded-full bg-green-600 p-1 text-white">
            <MailCheck size={14} strokeWidth={3} />
          </div>
        ) : (
          <SendEmailButton id={item?.id} />
        ))}
      {isStaff && item?.flagged && (
        <Flag
          className={cn('absolute -right-2 -top-1.5 fill-current text-brand-valencia', {
            '-left-2 -top-3.5 right-0': isSender,
          })}
          size={16}
        />
      )}
      {isStaff && item?.markedUnread && (
        <div className="absolute left-0.5 -top-1 h-3.5 w-3.5 rounded-full border-2 border-white  bg-brand-valencia">
          <span className="absolute left-0 inline-flex h-full w-full animate-ping rounded-full bg-brand-valencia opacity-75"></span>
        </div>
      )}
    </div>
  )
}

export default MessageItem
