import Meerle from '@/assets/images/Meerle.png'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'
import { MessageCircle, X } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import useOpenChat from '@/pages/messages/hooks/useOpenChat'
import MessageList from './MessageList'
import MessageInput from '@/pages/messages/messages-board/MessageInput'
import { cn } from '@/utils/shadcn'
import SmileyPopup from './SmileyPopup'
import useSmileyStore from '@/stores/useSmileyStore'
import useAuthStore from '@/stores/useAuthStore'
import { useSearchParams } from "react-router-dom"

const Smiley = ({ hasUnreadChat, hidden }: { hasUnreadChat: boolean; hidden: boolean }) => {
  const [searchParams] = useSearchParams()
  const chatWith = searchParams.get('chat_with')
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const openChat = useOpenChat({ toThriveCs: true })
  const messageListRef = useRef<HTMLDivElement>(null)
  const { openPopup, setOpenPopup } = useSmileyStore()
  const { user } = useAuthStore()

  useEffect(() => {
    if (chatWith === 'CS') {
      setOpen(true)
    }
  }, [chatWith])

  useEffect(() => {
    if (open) {
      openChat()
    }
  }, [open])

  useEffect(() => {
    const shownHelpPopup = localStorage.getItem('shown_help_popup') == user?.id
    if (!shownHelpPopup && user?.companyMember && !user?.userThemes.length) {
      setTimeout(() => {
        setOpenPopup(true)
        localStorage.setItem('shown_help_popup', user?.id || '')
      }, 30000)
    }
  }, [setOpenPopup])

  return (
    <div
      className={cn(
        'fixed bottom-5 right-3 h-14 w-14 rounded-full md:bottom-6 md:right-6 md:h-[72px] md:w-[72px] z-20',
        { hidden },
      )}
      style={{ boxShadow: '0px 12px 24px 0px rgba(254, 242, 216, 0.6)' }}
    >
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger>
          <div className="relative h-14 w-14 cursor-pointer rounded-full md:h-[72px] md:w-[72px]">
            {hasUnreadChat && (
              <div className="absolute right-1 top-0 h-3 w-3 rounded-full border-2 border-white bg-brand-valencia md:h-3.5  md:w-3.5">
                <span className="absolute left-0 inline-flex h-full w-full animate-ping rounded-full bg-brand-valencia opacity-75"></span>
              </div>
            )}
            <img
              src={Meerle}
              alt="smile-image"
              className="h-full w-full rounded-full object-cover object-center"
            />
          </div>
        </PopoverTrigger>
        <PopoverContent
          align="end"
          alignOffset={80}
          sideOffset={-16}
          className="h-[550px] max-h-[calc(100vh-100px)] w-screen -translate-y-6 border-none bg-transparent px-4 shadow-none sm:w-[340px] sm:translate-y-0 sm:px-0"
        >
          <div
            className="grid h-full w-full grid-rows-[70px__1fr__64px] overflow-hidden rounded-3xl bg-white sm:grid-rows-[70px__1fr__75px]"
            style={{ boxShadow: '0px 12px 24px 0px rgba(0, 0, 0, 0.12)' }}
          >
            <div className="relative flex w-full flex-col items-center bg-secondary-arapawa p-2.5">
              <MessageCircle className="text-brand-valencia" />
              <p className="mt-0.5 text-white">{t('label.chat_with_thrive')}</p>
              <X
                className="absolute right-4 top-4 cursor-pointer text-white"
                size={20}
                strokeWidth={3}
                onClick={() => setOpen(false)}
              />
            </div>
            <MessageList ref={messageListRef} className="!h-full pb-2" />
            <div className="border-t">
              <MessageInput
                messageListRef={messageListRef}
                className="border-none px-3 py-2.5 lg:px-5 lg:py-3 [&>button]:h-10 [&>button]:w-10 [&>button]:bg-secondary-arapawa"
              />
            </div>
          </div>
        </PopoverContent>
      </Popover>

      {openPopup && <SmileyPopup setOpen={setOpen} />}
    </div>
  )
}

export default Smiley
