import { useMutationGraphQL, useQueryGraphQL } from '@/use-graphql'
import { campaignsQuery } from '../graphql/query'
import { CampaignsQuery } from '@/gql/graphql'
import { Button } from '@/components/ui/button'
import { FilePlus2, Trash2 } from 'lucide-react'
import CampaignForm from '../components/CampaignForm'
import useModalStore from '@/stores/useModalStore'
import { formatDate } from '@/utils/datetime'
import { deleteCampaignMutation } from '../graphql/mutation'

export type Campaign = CampaignsQuery['campaigns']['items'][0]

const CampaignList = () => {
  const { data, refetch } = useQueryGraphQL(['campaigns'], campaignsQuery, {
    pagination: { limit: 99 },
  })
  const { addModal } = useModalStore()

  const showModal = (data?: Campaign) => {
    addModal({
      name: 'campaign',
      Component: ({ close }) => <CampaignForm close={close} refetch={refetch} data={data} />,
      isFullPage: true,
    })
  }

  const regex = /@\[\{\{(\w+)\}\}\]\(\w+\)/g

  const handleDeleteCampaign = (e, id) => {
    e.stopPropagation()
    addModal({
      name: 'delete-campaign',
      Component: ({ close }) => {
        const deleteCampaignMutate = useMutationGraphQL(deleteCampaignMutation, { id })
        return (
          <div className="p-10">
            <h3>Do you want to delete this campaign?</h3>
            <div className="mt-10 flex justify-end gap-4">
              <Button
                onClick={async () => {
                  await deleteCampaignMutate.mutateAsync()
                  refetch()
                  close?.()
                }}
              >
                Yes
              </Button>
              <Button variant="outline" onClick={close}>
                No
              </Button>
            </div>
          </div>
        )
      },
      isCardStyle: true,
      maxWidth: 700,
    })
  }

  return (
    <div>
      <Button size="sm" variant="outline" className="mt-1" onClick={() => showModal()}>
        <FilePlus2 className="text-brand-torea" size={20} />
        Add new campaign
      </Button>
      <div className="grid gap-4 md:grid-cols-2">
        {data?.campaigns.items.map((campaign) => (
          <div
            key={campaign.id}
            onClick={() => showModal(campaign)}
            className="mt-4 cursor-pointer rounded border border-gray-200 p-3 hover:bg-gray-100 hover:shadow-md"
          >
            <div className="flex justify-between gap-4">
              <h1 className="text-lg font-bold text-brand-torea">{campaign.name}</h1>
              {(campaign.scheduledAt || campaign.sentAt) && (
                <div className="text-sm text-muted-foreground">
                  {formatDate(new Date(campaign.scheduledAt || campaign.sentAt), 'dd MMM yyyy')}
                </div>
              )}
            </div>
            <p className="my-2 line-clamp-2 shrink-0 text-sm">
              {campaign.message.replace(regex, '{{$1}}')}
            </p>
            <div className="flex items-center justify-between">
              <div className="mt-4 w-fit max-w-[260px] truncate rounded-full bg-brand-torea px-4 py-2 text-xs text-white">
                {campaign.segment.name}
              </div>
              <Button
                variant="outline"
                size="sm"
                className="mt-4"
                onClick={(e) => handleDeleteCampaign(e, campaign.id)}
              >
                <Trash2 className="text-brand-valencia hover:text-red-700" size={20} />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CampaignList
