import { useEffect, useState } from 'react'
import { parsePhoneNumber } from 'awesome-phonenumber'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import WaveIcon from '@/assets/icons/WaveIcon'
import { ArrowUpRight, Mail } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import PhoneCountryInput from '@/components/PhoneCountryInput'
import { Label } from '@/components/ui/label'
import { Checkbox } from '@/components/ui/checkbox'
import { Button } from '@/components/ui/button'
import { useMutationGraphQL, useQueryGraphQL } from '@/use-graphql'
import { emailLoginInfo, termsQuery } from './graphql/query'
import {
  acceptTermsMutation,
  requestTextLoginCode,
  fillRegisterInfoMutation,
} from './graphql/mutation'
import usePressEnter from '@/hooks/usePressEnter'
import useModalStore from '@/stores/useModalStore'
import SelectInput from '@/components/SelectInput'
import { UsersUserFamilyStatusEnum, UsersUserGenderEnum } from '@/gql/graphql'
import { DatePicker } from '@/components/DatePicker'
import { getFamilyStatusLabel, getGenderLabel, transformErrorMessage } from '@/utils/handle-string'
import { getDateString } from '@/utils/datetime'
import useLoadingStore from '@/stores/useLoadingStore'
import { sleep } from '@/utils/helper'
import { routes } from '@/routes/utils'
import RegisterImage from '@/assets/images/register.png'

const Register = () => {
  const navigate = useNavigate()
  const { setLoading } = useLoadingStore()
  const { orgType = '', orgSlug = '', emailCode = '' } = useParams()
  const { t } = useTranslation()
  const [checked, setChecked] = useState(false)
  const [phone, setPhone] = useState('')
  const [dialCode, setDialCode] = useState({
    code: 'NL',
    dialCode: '+31',
  })
  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>(undefined)
  const [gender, setGender] = useState(undefined)
  const [familyStatus, setFamilyStatus] = useState(undefined)
  const [shownError, setShownError] = useState(false)
  const [error, setError] = useState('')
  const convertedPhone = phone.startsWith('+') ? phone : `${dialCode.dialCode}${phone}`
  usePressEnter(checked ? handleRequestSmsCode : undefined)

  const mutation = useMutationGraphQL(
    requestTextLoginCode,
    {
      emailCode,
      changePhoneNumber: convertedPhone,
    },
    {
      onSuccess: () => {
        navigate(routes.login.getPath(emailCode), {
          state: { phoneNumber: convertedPhone },
        })
      },
      onError: (error) => {
        //@ts-ignore
        toast.error(transformErrorMessage(error))
      },
    },
    {
      'thrive-org': orgSlug,
      'org-type': orgType,
    },
  )

  const { data, error: queryError } = useQueryGraphQL(
    ['login-info'],
    emailLoginInfo,
    { emailCode },
    {
      retry: 0,
      enabled: !!emailCode,
    },
    {
      'thrive-org': orgSlug,
      'org-type': orgType,
    },
  )

  const user = data?.emailLoginInfo?.user

  const { data: termsData } = useQueryGraphQL(['terms'], termsQuery)
  const acceptTermsMutate = useMutationGraphQL(acceptTermsMutation, {
    input: { userEmail: user?.email || '', version: termsData?.terms.version || 0 },
  })
  const updateInfoMutate = useMutationGraphQL(fillRegisterInfoMutation, {
    code: emailCode,
    input: { gender, dateOfBirth: dateOfBirth && getDateString(dateOfBirth), familyStatus },
  })
  const { addModal } = useModalStore()

  const showTerms = () => {
    addModal({
      name: 'terms',
      header: t('terms.text_of_link'),
      Component: () => <div className="whitespace-pre-line">{termsData?.terms.content}</div>,
    })
  }

  useEffect(() => {
    if (user?.phoneNumber) {
      const parsedPhone = parsePhoneNumber(user.phoneNumber)
      setPhone(parsedPhone.number?.significant || '')
      setDialCode({
        code: parsedPhone.regionCode || 'NL',
        dialCode: `+${parsedPhone.countryCode}` || '+31',
      })
    }
  }, [user?.phoneNumber])

  useEffect(() => {
    if (orgType) {
      localStorage.setItem('org-type', orgType)
    }
  }, [orgType])

  async function handleRequestSmsCode() {
    if (!checked || !convertedPhone) return
    if (!phone) {
      setError(t('validation.require_phone_number'))
      return
    }
    if (!parsePhoneNumber(convertedPhone).valid) {
      setError(t('validation.invalid_phone_number'))
      return
    }
    setLoading(true)
    acceptTermsMutate.mutate()
    await Promise.all([mutation.mutateAsync(), sleep()])
    updateInfoMutate.mutate()
    setLoading(false)
  }

  const handleChangePhone = (value: string) => {
    setPhone(value)
    error && setError('')
  }

  if (!shownError && queryError) {
    if (
      queryError?.message.startsWith('email_code_has_been_used') ||
      queryError?.message.startsWith('email_code_replaced')
    ) {
      navigate('/')
      return
    }
    if (queryError?.message.startsWith('email_code_expired')) {
      toast.error(t('error.email_code_expired'), { toastId: 1 })
    } else {
      toast.error(transformErrorMessage(error), { toastId: 1 })
    }
    setShownError(true)
  }

  return (
    <div className="m-auto flex max-w-lg flex-col text-center md:max-w-5xl md:flex-row md:justify-center md:gap-5">
      <div className="relative mt-6 h-90 max-w-full px-4 md:h-[545px] md:w-[440px]">
        <img
          src={RegisterImage}
          className="h-full w-full rounded-[48px] object-cover brightness-95 filter"
        />
        <div className="absolute left-0 top-0 px-8 md:px-10 md:text-left">
          <h1 className="mt-7 font-playfair text-[38px] tracking-wide text-brand-torea md:text-5xl">
            {t('register.image_header')}
            <span className="ml-2 inline-block h-2 w-2 rounded-full bg-brand-cream"></span>
          </h1>
          <p className="mt-3 text-xl text-brand-torea">{t('register.image_subtitle')}</p>
        </div>
      </div>
      <div className="relative -mt-36 rounded-5xl bg-white px-5 py-8 md:mt-0 md:w-[440px] md:px-12">
        <WaveIcon className="m-auto h-8 w-8 text-brand-cream" />
        <div className="mb-1 p-4 text-secondary-arapawa">
          <h1 className=" font-playfair text-4xl tracking-wide">
            {t('label.salutation')} {user?.firstName} {user?.lastName}
          </h1>
          <p className="mt-4 font-medium">{t('register.title')}</p>
          <p>
            {data?.emailLoginInfo.organization.name} {t('register.subtitle')}
          </p>
          <div className="mt-3 flex items-center justify-center">
            <Mail className="mr-2 h-5 w-5 text-secondary-casper" />
            {user?.email}
          </div>
        </div>
        <DatePicker
          className="text-left"
          label={t('label.date_of_birth')}
          value={dateOfBirth}
          onChange={setDateOfBirth}
          placeholder={t('placeholder.date_of_birth')}
          format="dd MMMM yyyy"
          defaultValue={new Date('1970-01-01')}
        />
        <SelectInput
          className="mt-4"
          label={t('label.gender')}
          value={gender}
          onChange={setGender}
          options={Object.values(UsersUserGenderEnum).map((e) => ({
            label: getGenderLabel(t, e),
            value: e,
          }))}
        />
        <SelectInput
          className="mt-4"
          label={t('label.family_status')}
          value={familyStatus}
          onChange={setFamilyStatus}
          options={Object.values(UsersUserFamilyStatusEnum).map((e) => ({
            label: getFamilyStatusLabel(t, e),
            value: e,
          }))}
        />
        <PhoneCountryInput
          className="mt-4"
          label={t('label.mobile_number')}
          id="phone-input"
          value={phone}
          onChange={handleChangePhone}
          dialCode={dialCode}
          onDialCodeChange={setDialCode}
          error={error}
        />
        <div className="mt-4 flex items-center justify-end gap-2">
          <Label className="font-normal">
            {t('terms.agree_to')}{' '}
            <a className="underline hover:cursor-pointer" onClick={showTerms}>
              {t('terms.text_of_link')}
            </a>
          </Label>
          <Checkbox checked={checked} onCheckedChange={(e) => setChecked(e as boolean)} />
        </div>
        <div className="mt-4 flex justify-end">
          <Button disabled={!checked} onClick={handleRequestSmsCode}>
            {t('button.register')} <ArrowUpRight className="ml-2" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Register
