import { ThemesQueryQuery } from '@/gql/graphql'
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '../ui/dropdown-menu'
import { ChevronDown } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getThemeLink } from '@/utils/helper'
import useAuthStore from '@/stores/useAuthStore'
import Visual from '@/assets/images/visual.png'
import ThemeLogo from '../theme-logo/ThemeLogo'
import colors from '@/constants/colors'
import { useState } from "react"

const ThemesDropdown = ({ themes }: { themes?: ThemesQueryQuery }) => {
  const { t } = useTranslation()
  const { accountType } = useAuthStore()
  const [open, setOpen] = useState(false)

  return (
    <DropdownMenu key="articles" open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <button className="flex items-center gap-2 outline-none">
          {t('menu.themes')} <ChevronDown className="text-grey-black" size={18} />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        sideOffset={10}
        className="flex rounded-xl p-0 text-deep-cove"
      >
        <img src={Visual} className="w-64 self-stretch object-cover" />
        <div className="w-[300px] space-y-6 p-8">
          {themes?.themes.map((theme) => (
            <Link
              to={getThemeLink(theme.slug, theme.id, accountType)}
              key={theme.id}
              className="block"
              onClick={() => setOpen(false)}
            >
              <div className="group flex items-center gap-3 text-sm font-medium">
                <ThemeLogo
                  color={colors.deepCove}
                  url={theme.icon?.url || ''}
                  className="h-6 w-6"
                />
                <span className="underline-offset-4 group-hover:underline">{theme.name}</span>
              </div>
            </Link>
          ))}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default ThemesDropdown
