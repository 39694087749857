import { useMutationGraphQL } from '@/use-graphql'

import { useNavigate } from 'react-router-dom'

import { getParticipant } from '@/utils/message'
import { toast } from 'react-toastify'
import useMessageStore from '@/stores/useMessageStore'
import useLoadingStore from '@/stores/useLoadingStore'
import { useQueryClient } from '@tanstack/react-query'
import { createChatMutation } from '../graphql/mutation'
import { CreateChatInput } from '@/gql/graphql'
import { CONTACT_LIMIT } from '../contacts/ContactList'
import { transformErrorMessage } from '@/utils/handle-string'
import { routes } from '@/routes/utils'

const useOpenChat = (input: CreateChatInput) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { setLoading } = useLoadingStore()
  const { setActiveChat } = useMessageStore()
  const createChatMutate = useMutationGraphQL(
    createChatMutation,
    {
      input,
    },
    {
      onSuccess: (data: any) => {
        const participant = getParticipant(data.createChat)
        setActiveChat({
          id: data.createChat.id,
          name: participant.name,
          image: '',
        })
        queryClient.invalidateQueries({
          queryKey: ['chat-contacts', { pagination: { limit: CONTACT_LIMIT } }],
        })
        setLoading(false)
        if (input.toThriveCs) {
          return
        }
        navigate(routes.messages.getPath())
      },
      onError: (error: any) => {
        toast.error(transformErrorMessage(error))
        setLoading(false)
      },
    },
  )
  const openChat = () => {
    setLoading(true)
    createChatMutate.mutate()
  }

  return openChat
}

export default useOpenChat
