import { create } from 'zustand'

export interface ParticipantInfo {
  id: string
  name: string
  image?: string
}

type FilterType = "markedUnread" | "flagged" | "archived" | "none"

export interface MessageStore {
  activeChat: ParticipantInfo | null
  setActiveChat: (value: ParticipantInfo | null) => void
  search: string
  setSearch: (value: string) => void
  filter: FilterType
  setFilter: (value: FilterType) => void
}

const useMessageStore = create<MessageStore>((set) => ({
  activeChat: null,
  setActiveChat: (info: ParticipantInfo | null) => {
    set({ activeChat: info })
  },
  search: "",
  setSearch: (value) => set({ search: value }),
  filter: "none",
  setFilter: (value) => set({
    filter: value
  })
}))

export default useMessageStore
