import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { ModalChildProps } from '@/stores/useModalStore'
import { ReactNode } from 'react'

const ConfirmDialog = ({
  header,
  Component,
  callback,
  close,
}: {
  header?: ReactNode
  Component: React.FC<ModalChildProps>
  callback?: () => void
  close: () => void
}) => {
  const accept = () => {
    callback?.()
    close()
  }
  return (
    <AlertDialog open>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{header || 'Are you sure?'}</AlertDialogTitle>
          <AlertDialogDescription>
            <Component close={close} />
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={close}>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={accept}>Yes</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default ConfirmDialog
