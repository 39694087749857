import { motion } from 'framer-motion'
import { Outlet } from 'react-router-dom'
import Logo from '/large-logo-v2.png'
import LanguageSelect from "@/components/LanguageSelect"

const Layout = () => {
  return (
    <div className="overflow-hidden pt-6">
      <div className="flex w-full items-center justify-between max-w-screen-xl mx-auto">
        <div className="w-20" />
        <img src={Logo} alt="logo" className="m-auto my-2 h-8" />
        <div className="mr-4 lg:mr-10">
          <LanguageSelect />
        </div>
      </div>
      <motion.div
        initial={{ y: 25, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.4, ease: 'easeOut' }}
      >
        <Outlet />
      </motion.div>
    </div>
  )
}

export default Layout
