import { graphql } from '@/gql'

export const updateInfoMutation = graphql(`
  mutation UpdateInfoMutation($input: UserInput!) {
    updateProfile(input: $input) {
      ... on UserType {
        email
        phoneNumber
      }
    }
  }
`)

export const updateFavoriteThemeMutation = graphql(`
  mutation UpdateFavoriteTheme($input: UpdateThemeInput!) {
    updateFavoriteTheme(input: $input)
  }
`)
