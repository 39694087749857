import { graphql } from '@/gql'

export const campaignUsersQuery = graphql(`
  query CampaignUsers($filters: UserFilter, $pagination: OffsetPaginationInput) {
    campaignUsers(filters: $filters, pagination: $pagination) {
      items {
        firstName
        lastName
        email
        phoneNumber
        id
        lastLogin
        companyMember {
          company {
            name
          }
          budget
        }
        userThemes {
          theme {
            name
          }
        }
      }
      totalItemsCount
    }
  }
`)

export const segmentsQuery = graphql(`
  query Segments($pagination: OffsetPaginationInput) {
    segments(pagination: $pagination) {
      items {
        contacts {
          totalItemsCount
        }
        name
        filters
        updatedAt
        id
      }
      totalItemsCount
    }
  }
`)

export const segmentDetailQuery = graphql(`
  query SegmentDetail($id: ID!, $pagination: OffsetPaginationInput, $search: String) {
    segmentDetail(id: $id) {
      contacts(pagination: $pagination, search: $search) {
        totalItemsCount
        items {
          firstName
          lastName
          email
          id
          lastLogin
          phoneNumber
          companyMember {
            company {
              name
            }
            budget
          }
          userThemes {
            theme {
              name
            }
          }
        }
      }
      name
      filters
      id
      updatedAt
    }
  }
`)

export const messageTemplatesQuery = graphql(`
  query MessageTemplates($search: String, $pagination: OffsetPaginationInput) {
    campaignMessageTemplates(search: $search, pagination: $pagination) {
      totalItemsCount
      items {
        id
        name
        content
      }
    }
  }
`)

export const campaignsQuery = graphql(`
  query Campaigns($search: String, $pagination: OffsetPaginationInput) {
    campaigns(search: $search, pagination: $pagination) {
      totalItemsCount
      items {
        id
        message
        name
        sentAt
        scheduledAt
        segment {
          contacts {
            totalItemsCount
          }
          id
          name
        }
      }
    }
  }
`)