import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { add, isAfter } from 'date-fns'
import { Smartphone } from 'lucide-react'

import LoginVerifyImage from '@/assets/images/LoginVerifyImage.png'
import OtpInput from '@/components/OtpInput'
import useCountDown from '@/hooks/useCountDown'
import { cn } from '@/utils/shadcn'
import { useMutationGraphQL } from '@/use-graphql'
import { requestTextLoginCode, login } from './graphql/mutation'
import useModalStore from '@/stores/useModalStore'
import OrganizationSelect from './OrganizationSelect'
import { LoginMutation, UserType } from '@/gql/graphql'
import { renderOrganizationInfo, transformErrorMessage } from '@/utils/handle-string'
import useAuthStore from '@/stores/useAuthStore'
import { routes } from '@/routes/utils'

const RESEND_TIME_INTERVAL = 30 * 1000

const VerifyCode = () => {
  const navigate = useNavigate()
  const { addModal } = useModalStore()
  const { emailCode = '' } = useParams()
  const { setUser } = useAuthStore()
  // When accessing this page by link in the email (requested in the login page)
  // we will auto-request the sms code.
  const { state } = useLocation()
  const phoneNumber = state?.phoneNumber
  const fromRegister = !!phoneNumber
  const { t } = useTranslation()
  const [otp, setOtp] = useState('')
  const [error, setError] = useState('')
  const [key, setKey] = useState(1)
  const {
    timeLeft,
    actions: { start },
  } = useCountDown(RESEND_TIME_INTERVAL)
  const requestTextLoginCodeMutation = useMutationGraphQL(
    requestTextLoginCode,
    {
      emailCode,
      changePhoneNumber: phoneNumber,
    },
    {
      onError: (error) => {
        //@ts-ignore
        toast.error(transformErrorMessage(error))
      },
    },
  )
  const loginMutation = useMutationGraphQL(
    login,
    {
      emailCode,
      textCode: otp,
    },
    {
      onSuccess: (data) => {
        const _data = data as LoginMutation
        const user = _data.login
        if (fromRegister) {
          try {
            // @ts-ignore
            window?._paq?.push?.([
              'trackEvent',
              'CompletedRegistration',
              'Complete Registration',
              `User: ${user?.email}. ${renderOrganizationInfo(user as UserType)}`,
            ])
          } catch (err) {
            console.log(err)
          }
        }
        // @ts-ignore
        setUser({ ...user })
        const redirect = () => {
          const isCompanyAdmin = user?.companyMember?.isAdmin || user?.companyMember?.isOwner
          return state?.from
            ? navigate(`${state.from.pathname}${state.from.search || ''}`)
            : isCompanyAdmin
            ? navigate(routes.companyDashboard.getPath())
            : navigate('/')
        }
        if (!localStorage.getItem('org-type')) {
          if (user?.companyMember && user?.vendorMember) {
            addModal({
              name: 'select-org',
              header: 'Choose your organization',
              Component: ({ close }) => (
                <div>
                  <OrganizationSelect user={user} close={close} />
                </div>
              ),
              callback: () => redirect(),
              isAlert: true,
            })
          } else {
            localStorage.setItem('org-type', user?.companyMember ? 'company' : 'vendor')
            redirect()
          }
        } else {
          localStorage.setItem('org-type', user?.companyMember ? 'company' : 'vendor')
          redirect()
        }
      },
      onError: () => {
        setError(t('validation.invalid_code'))
        setTimeout(() => {
          setError('')
          setOtp('')
          // Completely reset the OTP input after 2 seconds in error case
          setKey((k) => k + 1)
        }, 2000)
      },
    },
  )

  useEffect(() => {
    start()
    if (!fromRegister && emailCode) {
      // Prevent Auto Requesting Text Code too many times every refresh page (only request after 30s)
      const latestAutoRequestTextCode = localStorage.getItem('latestAutoRequestTextCode')
      if (
        !latestAutoRequestTextCode ||
        isAfter(new Date(), add(new Date(latestAutoRequestTextCode), { seconds: 30 }))
      ) {
        localStorage.setItem('latestAutoRequestTextCode', new Date().toISOString())
        requestTextLoginCodeMutation.mutate()
      }
    }
  }, [])

  const resendCode = async () => {
    if (timeLeft) return

    await requestTextLoginCodeMutation.mutateAsync()
    toast.success(t('notification.resent_sms_code'))
    start()
  }

  const handleLogin = () => {
    loginMutation.mutate()
  }

  return (
    <div className="m-auto flex max-w-lg flex-col text-center md:mt-20 md:max-w-5xl md:flex-row md:justify-center md:gap-5">
      <div className="relative mt-6 h-[440px] max-w-full px-4 md:h-[545px] md:w-[440px]">
        <img
          src={LoginVerifyImage}
          className="h-full w-full rounded-[48px] object-cover brightness-95 filter"
        />
      </div>
      <div className="relative -mt-28 rounded-5xl bg-white px-5 py-8 md:mt-16 md:w-[440px] md:px-12">
        <Smartphone className="m-auto h-8 w-8 text-brand-cream" />
        <div className="mb-5 p-4 md:mb-11">
          <h1 className="mb-3 font-playfair text-4xl tracking-wide text-brand-torea">
            {t('verify_sms_code.title')}
          </h1>
          <p className="text-primary">{t('verify_sms_code.description')}</p>
        </div>

        <div className="flex justify-center">
          <OtpInput
            key={key}
            label={t('label.sms_code')}
            value={otp}
            onChange={setOtp}
            onFinish={handleLogin}
            error={error}
          />
        </div>

        <div className="mt-6 flex flex-col items-center">
          <p className="text-sm font-medium text-primary">
            {t('verify_sms_code.not_receive_code_question')}
          </p>
          <button
            className={cn('text-sm font-medium text-primary underline underline-offset-4', {
              'text-gray-400': !!timeLeft,
            })}
            disabled={!!timeLeft}
            onClick={resendCode}
          >
            {timeLeft ? ` ${Math.floor(timeLeft / 1000)}s` : t('verify_sms_code.resend_code')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default VerifyCode
