import { cn } from '@/utils/shadcn'
import MessageItem from './MessageItem'
import useMessageStore from '@/stores/useMessageStore'
import { forwardRef, useEffect } from 'react'
import { getNextPageParam, useInfiniteQueryGraphQL, useMutationGraphQL } from '@/use-graphql'
import { messagesQuery } from '../graphql/query'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Loader2 } from 'lucide-react'
import Loading from '@/components/Loading'
import { ErrorMessage } from '@/components/ErrorMessage'
import { useTranslation } from 'react-i18next'
import { readChatMutation } from '../graphql/mutation'
import { ErrorBoundary } from '@sentry/react'
import useAuthStore from '@/stores/useAuthStore'
import useDebounceValue from '@/hooks/useDebounceValue'

export const MESSAGE_LIMIT = 15

const MessageList = forwardRef<HTMLDivElement, { className?: string }>(({ className }, ref) => {
  const { t } = useTranslation()
  const { activeChat, search, filter, setSearch, setFilter } = useMessageStore()
  const debounceSearch = useDebounceValue(search)
  const { isStaff, user } = useAuthStore()
  const { data, fetchNextPage, hasNextPage, status, error } = useInfiniteQueryGraphQL(
    ['messages'],
    messagesQuery,
    {
      chatId: activeChat?.id || '',
      pagination: { limit: MESSAGE_LIMIT },
      search: debounceSearch,
      filters: filter !== 'none' ? { [filter]: true } : undefined,
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return getNextPageParam({
          allPages,
          limit: MESSAGE_LIMIT,
          totalCount: lastPage?.chat?.messages.totalItemsCount,
        })
      },
      enabled: !!activeChat?.id,
      refetchInterval: 3000,
      staleTime: 1000,
    },
  )
  const readChatMutate = useMutationGraphQL(readChatMutation, { chatId: activeChat?.id || '' })

  useEffect(() => {
    if (activeChat) {
      readChatMutate.mutate()
      setSearch('')
      setFilter('none')
    }
    return () => {
      activeChat && readChatMutate.mutate()
      setSearch('')
      setFilter('none')
    }
  }, [activeChat?.id])

  if (!activeChat) {
    return <div className="h-[calc(100%-50px)] md:h-[calc(100%-90px)]" />
  }
  if (status === 'loading') {
    return (
      <div className="h-[calc(100%-50px)] md:h-[calc(100%-90px)]">
        <Loading />
      </div>
    )
  }
  if (status === 'error') {
    // @ts-ignore
    return (
      <div className="h-[calc(100%-50px)] md:h-[calc(100%-90px)]">
        <ErrorMessage message={error?.message} />
      </div>
    )
  }
  if (data === undefined || data?.pages?.[0]?.chat.messages.totalItemsCount === 0) {
    return (
      <div className="h-[calc(100%-50px)] md:h-[calc(100%-90px)]">
        <h5 className="my-10 text-center text-2xl text-brand-torea">{t('title.no_data')}</h5>
      </div>
    )
  }

  const dataLength = data.pages.reduce((counter, page) => {
    return counter + page.chat.messages.totalItemsCount || 0
  }, 0)

  return (
    <ErrorBoundary fallback={<ErrorMessage />}>
      <div
        className={cn(
          'flex h-[calc(100%-50px)] flex-col-reverse gap-4 overflow-auto px-4 pt-2 md:h-[calc(100%-90px)] ',
          className,
        )}
        ref={ref}
        id="scrollbar-target"
      >
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchNextPage}
          inverse
          scrollThreshold={0.7}
          hasMore={!!hasNextPage}
          loader={<Loader2 size={30} className="mx-auto my-8 animate-spin" />}
          scrollableTarget="scrollbar-target"
          style={{ display: 'flex', flexDirection: 'column-reverse', gap: 12, paddingTop: 16 }}
        >
          {data?.pages.map((group) =>
            group.chat.messages?.items?.map((e) => (
              <MessageItem
                key={e.id}
                item={e}
                isStaff={isStaff() || user?.isCustomerSupport || false}
              />
            )),
          )}
        </InfiniteScroll>
      </div>
    </ErrorBoundary>
  )
})

export default MessageList
