import { Calendar as CalendarIcon, X } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { cn } from '@/utils/shadcn'
import { formatDate } from '@/utils/datetime'
import { useTranslation } from 'react-i18next'
import { Label } from './ui/label'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from './ui/sheet'

export interface Props {
  value?: Date | null
  onChange: (date?: any) => void
  label?: string
  placeholder?: string
  className?: string
  mobileHeader?: string
  mobileFooter?: string
  format?: string
  defaultValue?: Date
  showClearButton?: boolean
}

export function DatePicker({
  value,
  onChange,
  label,
  placeholder,
  className,
  mobileHeader,
  mobileFooter,
  format,
  defaultValue,
  showClearButton,
}: Props) {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  const trigger = (
    <Button
      variant={'outline'}
      className={cn(
        'w-full max-w-2xl justify-start rounded !bg-transparent px-4 text-left text-base font-normal text-primary',
        !value && 'text-muted-foreground',
      )}
    >
      <CalendarIcon className="mr-2 !text-secondary-casper" size={18} />
      {value ? (
        <span className="capitalize">{formatDate(value, format || 'EEEE dd MMMM')}</span>
      ) : (
        <span>{placeholder || t('placeholder.pick_date')}</span>
      )}
    </Button>
  )

  if (width < 480) {
    return (
      <div className={className}>
        <Sheet>
          <div className="w-full">
            <Label className="mb-1 block text-secondary-arapawa">{label}</Label>
            <SheetTrigger asChild>{trigger}</SheetTrigger>
          </div>
          <SheetContent side="bottom" className="rounded-t-4xl p-0">
            <div className="flex flex-col">
              <SheetHeader className="mb-4">
                <SheetTitle>{mobileHeader || t('placeholder.pick_date')}</SheetTitle>
              </SheetHeader>
              <div className="w-full px-4">
                <Calendar
                  mode="single"
                  selected={value || defaultValue}
                  onSelect={onChange}
                  initialFocus
                  captionLayout="dropdown-buttons"
                  fromYear={1920}
                  toYear={2050}
                />
              </div>
            </div>
            <div className="mt-6 border-t border-secondary-casper px-6 py-3">
              <SheetTrigger asChild>
                <Button className="w-full">{mobileFooter || t('button.save')}</Button>
              </SheetTrigger>
            </div>
          </SheetContent>
        </Sheet>
      </div>
    )
  }

  return (
    <div className={className}>
      <Popover>
        <div className="w-full">
          <div className="flex items-center">
            <Label className="mb-1 block text-secondary-arapawa">{label}</Label>
            {showClearButton && value && (
              <X
                className="mb-1 ml-4 cursor-pointer text-brand-valencia hover:scale-110"
                size={20}
                onClick={() => onChange(null)}
              />
            )}
          </div>
          <PopoverTrigger asChild>{trigger}</PopoverTrigger>
        </div>
        <PopoverContent className="w-auto p-0" side="bottom" align="start">
          <Calendar
            mode="single"
            selected={value || defaultValue}
            onSelect={onChange}
            initialFocus
            required
            captionLayout="dropdown-buttons"
            fromYear={1920}
            toYear={2050}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}
