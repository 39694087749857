import i18next from 'i18next'
import { enUS, nl } from 'date-fns/esm/locale'
import { format as dateFnsFormat, isSameDay } from 'date-fns'

const LocaleConfig: Record<string, Locale> = {
  en: enUS,
  nl,
}

export const formatDate = (date: Date, format: string) => {
  const currentLanguage = i18next.language
  return dateFnsFormat(date, format, { locale: LocaleConfig[currentLanguage || 'nl'] })
}

export const getDateString = (date: Date) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}`
}

export const formatDateTimeForMessage = (date: Date) => {
  if (isSameDay(date, new Date())) {
    return formatDate(date, 'HH:mm')
  }
  return formatDate(date, 'dd MMM')
}
